import {parser} from "@/utils/parser";

export const state = () => ({
  city_id: null,
  query: "",
  page: 1,
  count: 12,
  pending: false,
  meta: null,
  events: [],
  lang: null,
  categories: [],
  seasons: [],
  categoriesForCheckbox: [],
  seasonsForCheckbox: [],
  age_restriction_id: null,
  started_at: null,
  stopped_at: null,
  place_id: null,
});

export const mutations = {
  reset: (state) => {
    state.events = [];
    state.meta = null;
    state.query = "";
    state.page = 1;
    state.city_id = null;
    state.categoriesForCheckbox = state.categoriesForCheckbox.map(item => ({...item, checked: false}));
    state.seasonsForCheckbox = state.seasonsForCheckbox.map(item => ({...item, checked: false}));
    state.started_at = null;
    state.stopped_at = null;
    state.accessible_environment = false;
    state.promo = false;
    state.place_id = null;
    state.age_restriction_id = null;
  },
  clear: (state) => {
    state.events = [];
    state.meta = null;
  },
  setEvents: (state, events) => state.events = [...state.events, ...events],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => state.pending = pending,
  setLang: (state, lang) => state.lang = lang,
  setCityId: (state, city_id) => state.city_id = city_id ? Number(city_id) : null,
  setQuery: (state, query) => state.query = query,
  setPage: (state, page) => state.page = page,
  setCount: (state, count) => state.count = count,
  setCategories: (state, categories) => {
    state.categories = categories;
    // if (state.categoriesForCheckbox.length === 0) {
      state.categoriesForCheckbox = categories.map(item => ({
        id: item.id,
        title: item.title,
        checked: false
      }));
    // }
  },
  setSeasons: (state, seasons) => {
    state.seasons = seasons;
    // if (state.seasonsForCheckbox.length === 0) {
      state.seasonsForCheckbox = seasons.map(item => ({
        id: item.id,
        title: item.title,
        checked: false
      }));
    // }
  },
  setCategoriesForCheckbox: (state, categoriesForCheckbox) => state.categoriesForCheckbox = categoriesForCheckbox,
  setSeasonsForCheckbox: (state, seasonsForCheckbox) => state.seasonsForCheckbox = seasonsForCheckbox,
  setCategoryIdFromString: (state, categoryString) => {
    if (categoryString) {
      const array = categoryString.split(",");
      array.forEach(id => {
        state.categoriesForCheckbox.forEach(item => {
          if (Number(item.id) === Number(id)) {
            item.checked = true;
          }
        });
      });
    } else {
      state.categoriesForCheckbox.forEach(item => item.checked = false);
    }
  },
  setSeasonIdFromString: (state, seasonString) => {
    if (seasonString) {
      const array = seasonString.split(",");
      array.forEach(id => {
        state.seasonsForCheckbox.forEach(item => {
          if (Number(item.id) === Number(id)) {
            item.checked = true;
          }
        });
      });
    } else {
      state.seasonsForCheckbox.forEach(item => item.checked = false);
    }
  },
  setAgeRestrictionId: (state, age_restriction_id) => state.age_restriction_id = age_restriction_id ? Number(age_restriction_id) : null,
  setDate: (state, dates) => {
    if (dates.length > 1) {
      state.started_at = dates[0];
      state.stopped_at = dates[1];
    }
  },
  setStartedAt: (state, started_at) => state.started_at = started_at,
  setStoppedAt: (state, stopped_at) => state.stopped_at = stopped_at,
  setPlaceId: (state, place_id) => state.place_id = place_id ? Number(place_id) : null,
};

export const actions = {
  async search({commit, state}, payload) {
    commit("setPending", true);
    if (payload?.clear) {
      commit("clear");
    }
    await this.$axios.$get(`/api/search/events?${parser({
      params: {
        city_id: state.city_id,
        query: state.query,
        count: state.count,
        page: state.page,
        resolution: "micro",
        lang: state.lang,
      }
    })}`)
      .then((resolve) => {
        commit("setEvents", resolve.data);
        commit("setMeta", resolve.meta);
      })
      .catch(() => null)
      .finally(() => commit("setPending", false));
  },
  async events({state, commit}, payload) {
    commit("setPending", true);
    if (payload?.clear) {
      commit("clear");
    }
    await this.$axios.$get(`/api/events?${parser({
      params: {
        resolution: "micro",
        city_id: state.city_id,
        age_restriction_id: state.age_restriction_id,
        season_id: state.seasonsForCheckbox.filter(item => item.checked === true).map(item => item.id).join(","),
        category_id: state.categoriesForCheckbox.filter(item => item.checked === true).map(item => item.id).join(","),
        started_at: state.started_at,
        stopped_at: state.stopped_at,
        page: state.page,
        count: state.count,
        lang: state.lang,
        place_id: state.place_id,
      }
    })}`)
      .then(response => {
        commit("setEvents", response.data);
        commit("setMeta", response.meta);
      })
      .catch(() => null)
      .finally(() => commit("setPending", false));
  }
};

export const getters = {
  dates: state => [state.started_at, state.stopped_at],
  place_id: state => state.place_id,
  schedule_started_at: state => state.started_at,
  schedule_stopped_at: state => state.stopped_at,
  age_restriction_id: state => state.age_restriction_id,
  categoriesForCheckbox: state => state.categoriesForCheckbox,
  seasonsForCheckbox: state => state.seasonsForCheckbox,
  events: state => state.events,
  meta: state => state.meta,
  pending: state => state.pending,
  city_id: state => state.city_id,
  query: state => state.query,
  page: state => state.page,
  count: state => state.count
};
