export const append = (state) => {
  const data = new FormData();
  if (state.edit) {
    data.append("_method", "PUT");
  }
  if (state.title) {
    data.append("title", state.title);
  }
  if (state.annotation) {
    data.append("annotation", state.annotation);
  }
  if (state.description) {
    data.append("description", state.description);
  }
  if (state.agency_id) {
    data.append("agency_id", state.agency_id);
  }
  if (state.city_id) {
    data.append("city_id", state.city_id);
  }
  if (state.category_id) {
    data.append("category_id", state.category_id);
  }
  if (state.tourism_type_id) {
    data.append("tourism_type_id", state.tourism_type_id);
  }
  if (state.distance_int) {
    const distance_int = state.distance_int.replace(/[^0-9]/g, "");
    data.append("distance_int", distance_int);
  }
  if (state.duration_int) {
    data.append("duration_int", state.duration_int);
  }
  if (state.level_id) {
    data.append("level_id", state.level_id);
  }
  if (state.seasonsForCheckbox && state.seasonsForCheckbox.filter(item => item.checked === true).length > 0) {
    state.seasonsForCheckbox.filter(item => item.checked === true).forEach((item, index) => {
      data.append(`seasons[${index}]`, item.id);
    });
  }
  if (state.accessible_environment) {
    data.append("accessible_environment", state.accessible_environment ? "1" : "0");
  }
  if (state.pictures.length > 0) {
    state.pictures.forEach((item, index) => {
      data.append(`images[${index}]`, item.src); // Бек пока еще требует это поле как обязательное
      data.append(`pictures[${index}][src]`, item.src);
      data.append(`pictures[${index}][title]`, item.title || "");
      data.append(`pictures[${index}][link]`, item.link || "");
    });
  }
  if (state.places.length > 0) {
    state.places.forEach((place) => {
      data.append("places[]", place.id);
    });
  }
  if (state.additions.length > 0) {
    const additionsFiltered = state.additions.filter(item => item.key.length > 0 && item.value.length > 0);
    additionsFiltered.forEach((element, index) => {
      if (element.id.toString().includes("hash-")) {
        data.append(`additions[${index}][value]`, element.value);
        data.append(`additions[${index}][key]`, element.key);
      } else {
        data.append(`additions[${index}][value]`, element.value);
        data.append(`additions[${index}][key]`, element.key);
        data.append(`additions[${index}][id]`, element.id);
      }
    });
  } else {
    data.append("additions[]", state.additions);
  }
  return data;
};
