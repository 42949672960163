//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ArticleSection",
  components: {
    ArticleEntityCardsBlock: () => import("@/components/common/ArticleEntityCardsBlock"),
    DescriptionFoldingComponent: () => import("@/components/common/DescriptionFoldingComponent"),
    ArticleNotes: () => import("@/components/common/ArticleNotes"),
    EntityContent: () => import("@/components/common/EntityContent"),
    Gallery: () => import("@/components/common/Gallery"),
    SocialVideoFrameComponent: () => import("@/components/common/SocialVideoFrameComponent"),
  },
  props: {
    article: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    title() {
      return this?.article?.title;
    },
    description() {
      return this?.article?.description;
    },
    pictures() {
      return this?.article?.pictures?.map((item, index) => ({
        image: item.src,
        id: index,
        title: "",
        description: "",
        link: item.link,
        name: item.title
      })) || [];
    },
    places() {
      return this?.article?.places;
    },
    routes() {
      return this?.article?.routes;
    },
    excursions() {
      return this?.article?.excursions;
    },
    events() {
      return this?.article?.events;
    },
    news() {
      return this?.article?.news;
    },
    youtube() {
      return this?.article?.youtube;
    },
    rutube() {
      return this?.article?.rutube;
    },
    vkontakte() {
      return this?.article?.vk_iframe
    },
    articles() {
      return this?.article?.articles;
    },
    mediaSource() {
      return this?.article?.media_source || null
    }
  },
  methods: {
    addressEvent(event) {
      return event?.place?.address || event?.address || "";
    },
    type(item) {
      if (item?.entity === "news") {
        return this?.$t("components.article-section.type-news") || "";
      }
      if (item?.entity === "places") {
        return item?.type?.title || "";
      }
      if (item?.entity === "routes") {
        return item?.subtype?.title || "";
      }
      if (item?.entity === "excursions") {
        return item?.subtype?.title || "";
      }
      if (item?.entity === "events") {
        return item?.badge?.title || "";
      }
    }
  }
};
