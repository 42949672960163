//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SearchEvents',
  components: {
    EventCard: () => import('@/components/common/EventCard'),
    Button: () => import('@/components/common/Button'),
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard')
  },
  data () {
    return {
      count: 3,
      page: 1
    }
  },
  computed: {
    show () {
      return this?.events?.length > 0 && this?.page < this?.eventsMeta?.last_page
    },
    events () {
      return this.$store.getters['search/events']
    },
    eventsMeta () {
      return this.$store.getters['search/eventsMeta']
    }
  },
  methods: {
    address (event) {
      return event?.place?.address || event?.address || ''
    },
    next () {
      this.page += 1
      this.$store.dispatch('search/events', {
        params: {
          resolution: 'medium',
          query: this.$route.query.search,
          page: this.page,
          count: this.count
        }
      })
    }
  }
}
