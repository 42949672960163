//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "VKVideo",
  props: {
    vkvideo: {
      type: [String, null],
      default: () => ''
    }
  },
  computed: {
    src() {
      if (this?.vkvideo?.length > 0) {
        return this.vkvideo
      }
      return ''
    }
  }
};
