export const placeAppend = (state) => {
  const data = new FormData();
  if (state.agency_id) {
    data.append("agency_id", state.agency_id);
  }
  if (state.title) {
    data.append("title", state.title);
  }
  if (state.annotation) {
    data.append("annotation", state.annotation);
  }
  if (state.description) {
    data.append("description", state.description);
  }
  if (state.city_id) {
    data.append("city_id", state.city_id);
  }
  if (state.category_id) {
    data.append("category_id", state.category_id);
  }
  if (state.youtube_announcement !== null) {
    data.append("youtube", state.youtube_announcement);
  }
  if (state.rutube_announcement !== null) {
    data.append("rutube", state.rutube_announcement);
  }
  if (state.vk !== null) {
    data.append("vk", state.vk);
  }
  if (state.latitude) {
    data.append("latitude", state.latitude);
  }
  if (state.longitude) {
    data.append("longitude", state.longitude);
  }
  if (state.address) {
    data.append("address", state.address);
  }
  if (state.tourism_type_id) {
    data.append("tourism_type_id", state.tourism_type_id);
  }
  if (state.type_id) {
    data.append("type_id", state.type_id);
  }
  if (state.website || state.phone || state.email) {
    data.append("contacts", JSON.stringify({
      website: state.website,
      phone: state.phone,
      email: state.email
    }));
  }
  if (state.facebook || state.vkontakte || state.instagram || state.youtube || state.ok) {
    data.append("socials", JSON.stringify({
      facebook: state.facebook,
      vk: state.vkontakte,
      instagram: state.instagram,
      youtube: state.youtube,
      ok: state.ok
    }));
  }
  if (state.work_hours) {
    data.append("work_hours", JSON.stringify(state.work_hours));
  }
  data.append("accessible_environment", state.accessible_environment ? "1" : "0");
  if (state.pictures.length > 0) {
    state.pictures.forEach((item, index) => {
      data.append(`images[${index}]`, item.src); // Бек пока еще требует это поле как обязательное
      data.append(`pictures[${index}][src]`, item.src);
      data.append(`pictures[${index}][title]`, item.title || '');
      data.append(`pictures[${index}][link]`, item.link || '');
    });
  }
  if (state.edit) {
    data.append("_method", "PUT");
  }
  if (state.additions.length > 0) {
    const additionsFiltered = state.additions.filter(item => item.key.length > 0 && item.value.length > 0);
    additionsFiltered.forEach((element, index) => {
      if (element.id.toString().includes("hash-")) {
        data.append(`additions[${index}][value]`, element.value);
        data.append(`additions[${index}][key]`, element.key);
      } else {
        data.append(`additions[${index}][value]`, element.value);
        data.append(`additions[${index}][key]`, element.key);
        data.append(`additions[${index}][id]`, element.id);
      }
    });
  } else {
    data.append("additions[]", state.additions);
  }
  return data;
};
