//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MapNavigation",
  components: {
    MapNavigationLayer: () => import("@/components/common/MapNavigation/MapNavigationLayer.vue"),
    MapNavigationLayers: () => import("@/components/common/MapNavigation/MapNavigationLayers.vue"),
    MapNavigationTabs: () => import("@/components/common/MapNavigation/MapNavigationTabs.vue"),
    MapNavigationSearch: () => import("@/components/common/MapNavigation/MapNavigationSearch.vue"),
    MapNavigationTypes: () => import("@/components/common/MapNavigation/MapNavigationTypes.vue"),
    MapNavigationPlaces: () => import("@/components/common/MapNavigation/MapNavigationPlaces.vue"),
    MapNavigationPlace: () => import("@/components/common/MapNavigation/MapNavigationPlace.vue"),
    MapNavigationRoutes: () => import("@/components/common/MapNavigation/MapNavigationRoutes.vue"),
    MapNavigationRoute: () => import("@/components/common/MapNavigation/MapNavigationRoute.vue"),
  },
  props: {
    transform: {
      type: Boolean,
      default: () => false
    }
  }
};
