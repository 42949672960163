import { render, staticRenderFns } from "./MapNavigationRoutes.vue?vue&type=template&id=4974b724&"
import script from "./MapNavigationRoutes.vue?vue&type=script&lang=js&"
export * from "./MapNavigationRoutes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HorizontalEntityCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/HorizontalEntityCard/index.vue').default,Empty: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Empty/index.vue').default})
