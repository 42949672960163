async function request({
                         app,
                         store,
                         params,
                         query,
                         error,
                         $config
                       }) {
  const city_id = $config.singleCity ? store.getters["cities/default"].id : query.city_id ? query.city_id : null;
  store.commit("pages/category-collections/setCityId", city_id);
  store.commit("pages/category-collections/clear");
  store.commit("pages/category-collections/setEntity", params.entity);
  store.commit("pages/category-collections/setLang", app.i18n.locale);
  store.commit("pages/category-collections/setSlug", params.slug);
  store.commit("pages/category-collections/setSort", query.sort || store.getters["pages/category-collections/sort"]);
  store.commit("pages/category-collections/setPromo", query.promo);
  store.commit("pages/category-collections/setHasTickets", query.has_tickets);
  store.commit("pages/category-collections/setSeasons", store.getters["seasons/seasons"]);
  store.commit("pages/category-collections/setLevels", store.getters["levels/levels"]);
  store.commit("pages/category-collections/setLevelId", query.level_id);
  store.commit("pages/category-collections/setOpenNow", query.open_now);
  store.commit("pages/category-collections/setAccessibleEnvironment", query.accessible_environment);
  store.commit("pages/category-collections/setAgeRestrictions", store.getters["age-restrictions/age_restrictions"]);
  store.commit("pages/category-collections/setAgeRestrictionId", query.age_restriction_id);
  store.commit("pages/category-collections/setTourismTypes", query.tourism_type_id);
  store.commit("pages/category-collections/setCategories", store.getters[`categories/${params.entity}`]);
  if (params.entity === "events") {
    store.commit("pages/category-collections/setDates", [
      query.started_at || app.$moment().format("YYYY-MM-DD"),
      query.stopped_at || null
    ]);
  }
  if (params.entity === "excursions") {
    store.commit("pages/category-collections/setDates", [
      query.schedule_started_at || null,
      query.schedule_stopped_at || null
    ]);
  }
  await store.dispatch("pages/category-collections/category", {
    clear: true,
    entity: params.entity,
    slug: params.slug,
    params: {
      resolution: "micro",
      city_id,
      lang: app.i18n.locale,
      count: 12,
      page: 1,
    }
  })
    .catch((errors) => {
      error({statusCode: 404});
    });
}

export default async function ({
                                 from,
                                 app,
                                 store,
                                 params,
                                 query,
                                 error,
                                 $config
                               }) {
  await request({
    from,
    app,
    store,
    params,
    query,
    error,
    $config
  });

  if (process.client) {
    const paramsTo = params;
    const paramsFrom = from.params;
    if (paramsFrom.entity === paramsTo.entity && paramsFrom.slug === paramsTo.slug) {
      app.$instantReplaceQuery({});
      store.commit("pages/category-collections/setSort", "avg");
      await request({
        from,
        app,
        store,
        params,
        query,
        error,
        $config
      });
    }
  }
}
