//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
// import seo from '@/middleware/seo'

export default {
  name: 'AccountAgenciesView',
  components: {
    EmptyEntity: () => import('@/components/common/EmptyEntity/index'),
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    AgenciesCard: () => import('@/components/common/AgenciesCard/index')
  },
  layout: 'personal-area',
  // middleware: [seo],
  async fetch({
                store,
                app
              }) {
    await store.dispatch('user/agencies', {
      params: {
        resolution: 'micro',
        lang: app.i18n.locale,
        count: 100500
      }
    })
  },
  head() {
    return {
      title: this.$t('pages.account-agencies.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      agencies: 'user/agencies',
      pending: 'user/agenciesPending',
      toasterOpen: 'toasters/opened'
    }),
    link() {
      return `${this?.user?.user_account_page_url}/restxq/organization-add`
    },
  },
  mounted() {
    if(this.toasterOpen) {
      this.$store.commit("toasters/close");
    }
  }
}
