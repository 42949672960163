import { parser } from '@/utils/parser'

export const state = () => ({
  route: null,
  pending: false,
})

export const mutations = {
  setRoute: (state, route) => state.route = route,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/routes/${payload.id}?${parser(payload)}`)
      .then(response => commit('setRoute', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  route: state => state.route,
  pending: state => state.pending,

  accessibleEnvironment: state => state.route?.accessible_environment || false,
  additions: state => state.route?.additions || [],
  ageRestriction: state => state.route?.age_restriction || 0,
  agency: state => state.route?.agency || null,
  audios: state => state.route?.audios || [],
  caloriesInt: state => state.route?.calories_int || 0,
  currentUserRating: state => state.route?.currentUserRating || 0,
  description: state => state.route?.description || '',
  distanceInt: state => state.route?.distance_int || 0,
  durationInt: state => state.route?.duration_int || 0,
  entity: state => state.route?.entity || '',
  favorited: state => state.route?.favorited || false,
  guide: state => state.route?.guide || null,
  hasTickets: state => state.route?.has_tickets || false,
  id: state => state.route?.id || null,
  images: state => state.route?.images || [],
  pictures: state => state.route?.pictures || [],
  label: state => state.route?.label || '',
  languages: state => state.route?.languages || [],
  level: state => state.route?.level || null,
  places: state => state.route?.places || [],
  rating: state => state.route?.rating || 0,
  rutube: state => state.route?.rutube || '',
  vkontakte: state => state.route?.vk_iframe || '',
  season: state => state.route?.season || null,
  status: state => state.route?.status || '',
  tags: state => state.route?.tags || [],
  title: state => state.route?.title || '',
  type: state => state.route?.type || '',
  userTravels: state => state.route?.user_travels || null,
  youtube: state => state.route?.youtube || '',
  mediaSource: state => state.route?.media_source || null
}
