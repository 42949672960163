//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LayerNote",
  components: {
    RoundButton: () => import("@/components/common/RoundButton"),
  },
  props: {
    id: {
      type: Number,
      default: () => null
    },
    number: {
      type: Number,
      default: () => null
    },
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    color: {
      type: String,
      default: () => ""
    },
    link: {
      type: String,
      default: () => ''
    },
  },
  methods: {
    createWindow() {
      const popupCenter = ({url, title, w, h}) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop - (h / 2)
        const newWindow = window.open(url, title,
          `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
        )

        if (window.focus) newWindow.focus();
      }

      popupCenter({url: this.link, title: "oopt", w: 960, h: 600})
    }
  }
};
