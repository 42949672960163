//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import auth from '@/middleware/auth'
import seasons from '@/middleware/seasons'
import levels from '@/middleware/levels'
import age from '@/middleware/age'

export default {
  name: 'PersonalAreaAgencyLayout',
  components: {
    Icon24FillArrowLeft: () => import('@/assets/icons/Icon24FillArrowLeft.svg?inline'),
    ScrollToTopButton: () => import('@/components/common/ScrollToTopButton/index'),
    Disclaimer: () => import('@/components/common/Disclaimer/index'),
    MobileMenu: () => import('@/components/common/MobileMenu/index'),
    Footer: () => import('@/components/common/Footer/index'),
    Header: () => import('@/components/common/Header/index'),
    Popup: () => import('@/components/common/Popup/index'),
    Status: () => import('@/components/common/Status/index')
  },
  middleware: [auth, seasons, levels, age],
  computed: {
    ...mapGetters({
      agency: 'account-agency-profile/agency',
      logged: 'user/logged',
      token: 'user/token',
      user: 'user/user',
      show: 'popup/popup',
      browser: 'viewport/browser',
      buttonBackLink: 'layout-personal-area-agency/buttonBackLink',
      buttonBackTitle: 'layout-personal-area-agency/buttonBackTitle',
      os: 'viewport/os'
    }),
    type() {
      switch (this?.agency?.type) {
        case 'travel': {
          return this.$t('personalareaagencylayout.touroperator')
        }
        case 'org': {
          return this.$t('personalareaagencylayout.organisation')
        }
        case 'guide': {
          return this.$t('personalareaagencylayout.guide')
        }
        default: {
          return ''
        }
      }
    },
    title() {
      return this?.agency?.title
    },
    status() {
      return this?.agency?.status
    },
    tabs() {
      return [
        {
          link: `/account/agencies/${this.$route.params.agency_id}/profile`,
          name: this.$t('personalareaagencylayout.profile')
        },
        {
          link: `/account/agencies/${this.$route.params.agency_id}/excursions`,
          name: this.$t('personalareaagencylayout.excursions')
        },
        {
          link: `/account/agencies/${this.$route.params.agency_id}/events`,
          name: this.$t('personalareaagencylayout.events')
        },
        {
          link: `/account/agencies/${this.$route.params.agency_id}/orders`,
          name: this.$t('layouts.personal-area-agency-layout.big-tabs.orders')
        },
        {
          link: `/account/agencies/${this.$route.params.agency_id}/widgets`,
          name: this.$t('layouts.personal-area-agency-layout.big-tabs.widgets')
        }
      ]
    },
    excursion_id() {
      return this.$route.params.excursion_id
    },
    event_id() {
      return this.$route.params.event_id
    }
  },
  created() {
    if (this.token) {
      this.browser.name === 'Safari' || this.os.name === 'iOS'
        ? this.$axios.defaults.params = {api_token: this.token}
        : this.$axios.setToken(`Bearer ${this.token}`)
    }
    if (!this.logged) {
      this.$router.push('/login')
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    window.addEventListener('scroll', this.handleScroll)
    this.$store.commit('popup/close')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.$store.commit('viewport/setScroll', window.scrollY)
    },
    handleResize() {
      this.$store.commit('viewport/setViewport', {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })
    }
  }
}
