import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6db9e5bc&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserInfo: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/UserInfo/index.vue').default,BannerLoyaltyProgram: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/BannerLoyaltyProgram/index.vue').default,PromoCode: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/PromoCode/index.vue').default,Button: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Button/index.vue').default,EmptyEntity: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/EmptyEntity/index.vue').default,TravelCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/TravelCard/index.vue').default})
