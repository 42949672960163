export const state = () => ({
  customerEmail: null,
  customerPhone: null,
  customerFirstName: null,
  customerLastName: null,
  customerMiddleName: null,
  customerBirthDate: null,
  customerGender: null,
  customerCitizenship: null,
  customerRegion: null,
  orderParticipants: [],
  orderItems: [],
  regionsList: [],
  axiosConfig: {
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer uPYeLLMiMFSgj6YD7bu3gghS7mViOh6Jkv9YPYqHlhmw74zOTPxw8frEMxu9BgDfrsQhRBr9dOXgWVaD'
    }
  },
  checkPersonal: false,
  thirdPartyRule: false,
  acquaintedWithDocuments: false,
  customerErrors: {
    customerBirthDate: null,
    firstName: null,
    lastName: null,
    middleName: null,
  },
  nameLengthError:  'ФИО не должно быть длиннее 50 символов'
})

export const actions = {
  async getRegionsOfResidence({commit, state}) {
    if (state.regionsList.length === 0) {
      await this.$axios.$get('/api/regions', state.config)
        .then(response => {
          commit('setRegionsList', response.data)
        })
        .catch((errors) => {
          commit('setErrors', errors.response.data)
        })
    }
  },
  createOrder({commit, state}, payload) {
    const body = {
      order_items: payload.orderItems,
      customer_name: `${state.customerLastName ? state.customerLastName : ''} ${state.customerFirstName ? state.customerFirstName : ''} ${state.customerMiddleName ? state.customerMiddleName : ''}`,
      customer_email: state.customerEmail,
      customer_phone: state.customerPhone,
      customer_first_name: state.customerFirstName,
      customer_last_name: state.customerLastName,
      payable: true,
      success_url: payload.successUrl,
      fail_url: payload.failUrl
    }

    if (state.customerMiddleName) {
      body.customer_middle_name = state.customerMiddleName
    }
    if (state.customerBirthDate) {
      body.customer_birth_date = state.customerBirthDate
    }
    if (state.customerGender) {
      body.customer_gender = state.customerGender
    }
    if (state.customerCitizenship) {
      body.customer_citizenship = state.customerCitizenship
    }
    if (state.customerRegion) {
      body.customer_region = state.customerRegion
    }
    if (state.orderParticipants) {
      body.order_participants = state.orderParticipants
    }
    if (state.orderParticipants) {
      body.order_participants = state.orderParticipants
    }

    this.$axios.$post('/api/orders', JSON.stringify(body), state.axiosConfig)
      .then(response => {
        commit('clearData')
        window.location = response?.redirect_url;
      })
      .catch((errors) => {
        if (errors.response?.data?.error_message === "Customer age must be more 18 on date start of excursion.") {
          commit('setCustomerBirthDate', errors.response?.data?.error_message)
        }

        if (Array.isArray(errors.response?.data?.customer_name)) {
          if (errors.response?.data?.customer_name.includes('customer name не может превышать 50 символов.')) {
            commit('setCustomerNameError', 'ФИО не должно быть длиннее 50 символов')
          }
        }
      })
  },
}

export const mutations = {
  clearRegion: (state, data) => {
    if (data.mode === 'customer') {
      state.customerRegion = null
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].region = null
    }
  },
  setCustomerBirthDate: (state, value) => state.customerErrors.customerBirthDate = value,
  setErrors: (state, errors) => state.errors = errors,
  setEmail: (state, data) => state.customerEmail = data.value,
  setPhone: (state, data) => {
    if (data.mode === 'customer') {
      state.customerPhone = data.value
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].phone = data.value
    }
  },
  setFirstName: (state, data) => {
    if (data.mode === 'customer') {
      state.customerFirstName = data.value
      if (`${state.customerLastName} ${state.customerFirstName} ${state.customerMiddleName}`.length > 50) {
        state.customerErrors.firstName = state.nameLengthError
        state.customerErrors.lastName = state.nameLengthError
        state.customerErrors.middleName = state.nameLengthError
      } else {
        if (state.customerErrors.firstName === state.nameLengthError) {
          state.customerErrors.firstName = null
        }
        if (state.customerErrors.lastName === state.nameLengthError) {
          state.customerErrors.lastName = null
        }
        if (state.customerErrors.middleName === state.nameLengthError) {
          state.customerErrors.middleName = null
        }
      }
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].first_name = data.value
    }
  },
  setMiddleName: (state, data) => {
    if (data.mode === 'customer') {
      state.customerMiddleName = data.value
      if (`${state.customerLastName} ${state.customerFirstName} ${state.customerMiddleName}`.length > 50) {
        state.customerErrors.firstName = state.nameLengthError
        state.customerErrors.lastName = state.nameLengthError
        state.customerErrors.middleName = state.nameLengthError
      } else {
        if (state.customerErrors.firstName === state.nameLengthError) {
          state.customerErrors.firstName = null
        }
        if (state.customerErrors.lastName === state.nameLengthError) {
          state.customerErrors.lastName = null
        }
        if (state.customerErrors.middleName === state.nameLengthError) {
          state.customerErrors.middleName = null
        }
      }
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].middle_name = data.value
    }
  },
  setLastName: (state, data) => {
    if (data.mode === 'customer') {
      state.customerLastName = data.value
      if (`${state.customerLastName} ${state.customerFirstName} ${state.customerMiddleName}`.length > 50) {
        state.customerErrors.firstName = state.nameLengthError
        state.customerErrors.lastName = state.nameLengthError
        state.customerErrors.middleName = state.nameLengthError
      } else {
        if (state.customerErrors.firstName === state.nameLengthError) {
          state.customerErrors.firstName = null
        }
        if (state.customerErrors.lastName === state.nameLengthError) {
          state.customerErrors.lastName = null
        }
        if (state.customerErrors.middleName === state.nameLengthError) {
          state.customerErrors.middleName = null
        }
      }
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].last_name = data.value
    }
  },
  setBirthDate: (state, data) => {
    const birthdate = data.value
    if (data.mode === 'customer') {
      state.customerBirthDate = birthdate
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].birth_date = birthdate
    }

    if (
      typeof birthdate === 'string'
      && birthdate.length === 10
      && birthdate.split('-').length === 3
    ) {
      const [birthYear, birthMonth, birthDay] = birthdate.split('-').map(Number);

      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const currentDay = today.getDate();

      let age = currentYear - birthYear;

      if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
      }

      if (age < 18) {
        if (data.mode === 'customer') {
          state.customerErrors.customerBirthDate = 'Customer age must be more 18 on date start of excursion.'
        } else if (data.mode === 'participant') {
          state.orderParticipants[data.index].errors.birth_date = 'children'
        }
      } else {
        if (data.mode === 'customer') {
          state.customerErrors.customerBirthDate = null
        } else if (data.mode === 'participant') {
          state.orderParticipants[data.index].errors.birth_date = null
        }

      }
    }
  },
  setGender: (state, data) => {
    if (data.mode === 'customer') {
      state.customerGender = data.value
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].gender = data.value
    };
  },
  setCitizenship: (state, data) => {
    if (data.mode === 'customer') {
      state.customerCitizenship = data.value
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].citizenship = data.value
    };
  },
  setRegion: (state, data) => {
    if (data.mode === 'customer') {
      state.customerRegion = data.value
    } else if (data.mode === 'participant') {
      state.orderParticipants[data.index].region = data.value
    };
  },
  addNewOrderParticipant: (state) => {
    state.orderParticipants.push({
      first_name: null,
      last_name: null,
      middle_name: null,
      birth_date: null,
      gender: null,
      phone: null,
      citizenship: null,
      region: null,
      errors: {
        birth_date: null
      },
    })
  },
  deleteOneOrderParticipant: (state) => {
    state.orderParticipants = state.orderParticipants.slice(0, state.orderParticipants.length - 1)
  },
  setOrderItems: (state, orderItems) => state.orderItems = orderItems,
  setRegionsList: (state, regionsList) => state.regionsList = regionsList,
  setCheckPersonal: (state, checkPersonal) => state.checkPersonal = checkPersonal,
  setThirdPartyRule: (state, thirdPartyRule) => state.thirdPartyRule = thirdPartyRule,
  setAcquaintedWithDocuments: (state, acquaintedWithDocuments) => state.acquaintedWithDocuments = acquaintedWithDocuments,
  clearData(state) {
    state.errors = null
    state.customerEmail = null
    state.customerPhone = null
    state.customerFirstName = null
    state.customerLastName = null
    state.customerMiddleName = null
    state.customerBirthDate = null
    state.customerGender = null
    state.customerCitizenship = null
    state.customerRegion = null
    state.orderParticipants = []
    state.orderItems = []
    state.checkPersonal = false
    state.thirdPartyRule = false
    state.acquaintedWithDocuments = false
    state.customerErrors = {
      customerBirthDate: null,
      first_name: null,
      last_name: null,
      middle_name: null,
    }
  },
  setCustomerNameError: (state, value) => {
    state.customerErrors.firstName = value
    state.customerErrors.lastName = value
    state.customerErrors.middleName = value
  },
}

export const getters = {
  getErrors: state => state.errors,
  getCustomerName: state => state.customerName,
  getCustomerEmail: state => state.customerEmail,
  getCustomerPhone: state => state.customerPhone,
  getCustomerFirstName: state => state.customerFirstName,
  getCustomerLastName: state => state.customerLastName,
  getCustomerMiddleName: state => state.customerMiddleName,
  getCustomerBirthDate: state => state.customerBirthDate,
  getCustomerGender: state => state.customerGender,
  getCustomerCitizenship: state => state.customerCitizenship,
  getCustomerRegion: state => state.customerRegion,
  getOrderParticipants: state => state.orderParticipants,
  getOrderItems: state => state.orderItems,
  getRegionsList: state => state.regionsList,
  checkPersonal: state => state.checkPersonal,
  getThirdPartyRule: state => state.thirdPartyRule,
  getAcquaintedWithDocuments: state => state.acquaintedWithDocuments,
  getCustomerErrors: state => state.customerErrors,
}
