import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7aa816f4&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StatusDropDownMenu: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/StatusDropDownMenu/index.vue').default,Hint: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Hint/index.vue').default,Calendar: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Calendar/index.vue').default})
