//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NoteCard",
  components: {
    Divider: () => import("@/components/common/Divider"),
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    picture: {
      type: Object,
      default: () => null
    },
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    image() {
      return this?.picture?.src || "";
    },
    link() {
      return this?.picture?.link || "";
    },
    sign() {
      return this?.picture?.title || "";
    }
  },
  methods: {
    openPopupArticleNote() {
      this.$store.commit("popup/open", {
        name: "PopupArticleNote",
        params: {
          style: {
            maxWidth: "632px",
            maxHeight: "100vh"
          }
        },
        options: {
          image: this.image,
          sign: this.sign,
          link: this.link,
          title: this.title,
          description: this.description
        }
      });
    }
  }
};
