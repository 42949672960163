import {Plugin} from '@nuxt/types'

interface PopupOrderOptionsForProtectedAreas {
  date: number,
  title: string,
  time: string,
  tariffs: object[],
  agency: object | null,
}

declare module 'vue/types/vue' {
  // this.$openPopupOrder inside Vue components
  interface Vue {
    $openPupUpForProtectedAreas(options: PopupOrderOptionsForProtectedAreas): void
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$openPopupOrder inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $openPupUpForProtectedAreas(options: PopupOrderOptionsForProtectedAreas): void
  }

  // nuxtContext.$openPopupOrder
  interface Context {
    $openPupUpForProtectedAreas(options: PopupOrderOptionsForProtectedAreas): void
  }
}

declare module 'vuex/types/index' {
  // this.$openPopupOrder inside Vuex stores
  interface Store<S> {
    $openPupUpForProtectedAreas(options: PopupOrderOptionsForProtectedAreas): void
  }
}

const pluginOpenPupUpForProtectedAreas: Plugin = ({store, $config}, inject) => {
  inject('openPupUpForProtectedAreas', (options: PopupOrderOptionsForProtectedAreas): void => {
    store.commit('popup/open', {
      name: 'PopupOrderProtectedAreas',
      params: {
        style: {
          width: store.getters['viewport/sm'] || false ? '100%' : '632px',
          maxHeight: '100vh'
        }
      },
      options
    })
  })
}

export default pluginOpenPupUpForProtectedAreas
