import { render, staticRenderFns } from "./TopEntityComponentSlider.vue?vue&type=template&id=cdb5cf50&"
import script from "./TopEntityComponentSlider.vue?vue&type=script&lang=js&"
export * from "./TopEntityComponentSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EmptyTopEntityComponentSlider: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/EmptyTopEntityComponentSlider/index.vue').default,SignComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SignComponent/index.vue').default,ImageComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ImageComponent/index.vue').default,RoundButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/RoundButton/index.vue').default,SliderBullets: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SliderBullets/index.vue').default})
