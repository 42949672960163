import { parser } from '@/utils/parser'

export const state = () => ({
  event: null,
  pending: false,
})

export const mutations = {
  setEvent: (state, event) => state.event = event,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/events/${payload.id}?${parser(payload)}`)
      .then(response => commit('setEvent', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  event: state => state.event,
  pending: state => state.pending,

  accessibleEnvironment: state => state.event?.accessible_environment || false,
  additions: state => state.event?.additions || [],
  address: state => state.event?.address || '',
  agency: state => state.event?.agency || null,
  audios: state => state.event?.audios || [],
  bookingTerms: state => state.event?.booking_terms || null,
  cancellationTerms: state => state.event?.cancellation_terms || null,
  createdAt: state => state.event?.created_at || null,
  description: state => state.event?.description || '',
  entity: state => state.event?.entity || '',
  favorited: state => state.event?.favorited || false,
  hasTickets: state => state.event?.has_tickets || false,
  id: state => state.event?.id || null,
  images: state => state.event?.images || [],
  pictures: state => state.event?.pictures || [],
  label: state => state.event?.label || '',
  place: state => state.event?.place || null,
  rutube: state => state.event?.rutube || '',
  startedAt: state => state.event?.started_at || null,
  stoppedAt: state => state.event?.stopped_at || null,
  tags: state => state.event?.tags || [],
  title: state => state.event?.title || '',
  userTravels: state => state.event?.user_travels || null,
  yabilet: state => state.event?.yabilet || null,
  youtube: state => state.event?.youtube || '',
  vkontakte: state => state.event?.vk_iframe || '',
  mediaSource: state => state.event?.media_source || null,
  seasons: state => state.event?.seasons || []
}
