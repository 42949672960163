//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'SearchPlaces',
  components: {
    PlaceCard: () => import('@/components/common/PlaceCard'),
    Button: () => import('@/components/common/Button'),
    HorizontalEntityCard: () => import('@/components/common/HorizontalEntityCard')
  },
  data () {
    return {
      count: 3,
      page: 1
    }
  },
  computed: {
    show () {
      return this?.places?.length > 0 && this?.page < this?.placesMeta?.last_page
    },
    places () {
      return this.$store.getters['search/places']
    },
    placesMeta () {
      return this.$store.getters['search/placesMeta']
    }
  },
  methods: {
    next () {
      this.page += 1
      this.$store.dispatch('search/places', {
        params: {
          resolution: 'medium',
          query: this.$route.query.search,
          page: this.page,
          count: this.count
        }
      })
    }
  }
}
