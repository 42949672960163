export default async function ({
  $config,
  app,
  store
}) {
  await store.dispatch('account-agency-places/fetch', {
    clear: true,
    params: {
      page: 1,
      count: 9,
      resolution: 'micro',
      lang: app.i18n.locale,
      city_id: $config.singleCity ? store.getters['cities/default'].id : null,
      // agency_id: store.getters['account-agency-profile/agency'].id
    }
  })
}
