//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AccountFavoritesEventsView',
  components: {
    Empty: () => import('@/components/common/Empty/index'),
    EventCard: () => import('@/components/common/EventCard/index')
  },
  layout: 'personal-area',
  data () {
    return {
      loading: false
    }
  },
  head () {
    return {
      title: this.$t('pages.account-favorites-events.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      events: 'favorites/events',
      city: 'cities/default'
    }),
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  mounted () {
    this.loading = true
    this.$store.dispatch('favorites/fetch', {
      entity: 'events',
      params: {
        count: 100500,
        lang: this.$i18n.locale,
        city_id: this.$config.singleCity ? this.city.id : null
      }
    })
      .then(() => this.loading = false)
      .catch(() => this.loading = false)
      .finally(() => this.loading = false)
  },
  methods: {
    typeEvent (event) {
      return event?.badge?.title || this.$t('pages.accountfavoriteseventsview.eventcard.type')
    },
    addressEvent (event) {
      return event?.place?.address || event?.address || ''
    }
  }
}
