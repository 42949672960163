import {errors} from "@/utils/errors";
import {agencyAppend} from "@/utils/agency-append";
import defaultStore from "@/utils/agency-default-store-config";

export const state = () => ({
  title: null,
  description: null,
  address: null,
  email: null,
  phone: null,
  website: null,
  facebook: null,
  vk: null,
  instagram: null,
  ok: null,
  youtube: null,
  telegram: null,
  city_id: null,
  latitude: null,
  longitude: null,
  image: null,
  errors: null,
  type_id: 0,
  pending: false,
  status: null,
  config: {headers: {"Content-Type": "multipart/form-data"}},
  additions: []
});

export const mutations = {
  deleteErrorsByKey: (state, key) => state.errors = errors(key, state.errors),
  setAdditions: (state, additions) => {
    state.additions = additions
    state.errors = errors('additions', state.errors)
  },
  setTitle: (state, title) => {
    state.title = title;
    state.errors = errors("title", state.errors);
  },
  setDescription: (state, description) => {
    state.description = description;
    state.errors = errors("description", state.errors);
  },
  setAddress: (state, address) => {
    state.address = address;
    state.errors = errors("address", state.errors);
  },
  setEmail: (state, email) => {
    state.email = email;
    state.errors = errors("email", state.errors);
  },
  setPhone: (state, phone) => {
    state.phone = phone;
    state.errors = errors("phone", state.errors);
  },
  setWebsite: (state, website) => {
    state.website = website;
    state.errors = errors("website", state.errors);
  },
  setFacebook: (state, facebook) => {
    state.facebook = facebook;
    state.errors = errors("facebook", state.errors);
  },
  setVk: (state, vk) => {
    state.vk = vk;
    state.errors = errors("vk", state.errors);
  },
  setInstagram: (state, instagram) => {
    state.instagram = instagram;
    state.errors = errors("instagram", state.errors);
  },
  setOk: (state, ok) => {
    state.ok = ok;
    state.errors = errors("ok", state.errors);
  },
  setYoutube: (state, youtube) => {
    state.youtube = youtube;
    state.errors = errors("youtube", state.errors);
  },
  setTelegram: (state, telegram) => {
    state.telegram = telegram;
    state.errors = errors("telegram", state.errors);
  },
  setCityId: (state, city_id) => {
    state.city_id = city_id;
    state.errors = errors("city_id", state.errors);
  },
  setLatitude: (state, latitude) => {
    state.latitude = latitude;
    state.errors = errors("latitude", state.errors);
  },
  setLongitude: (state, longitude) => {
    state.longitude = longitude;
    state.errors = errors("longitude", state.errors);
  },
  setImage: (state, image) => {
    state.image = image;
    state.errors = errors("image", state.errors);
  },
  setErrors: (state, errors) => state.errors = {...state.errors, ...errors},
  setTypeId: (state, type_id) => {
    state.type_id = type_id;
    state.errors = errors("type_id", state.errors);
  },
  setPending: (state, pending) => {
    state.pending = pending;
    state.errors = errors("pending", state.errors);
  },
  setStatus: (state, status) => {
    state.status = status;
  },

  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key];
    }
  }
};

export const actions = {
  async submit({state, commit}, payload) {
    commit("setPending", true);
    const data = agencyAppend(state);
    await this.$axios.$post(`/api/agencies/${payload.id}`, data, state.config)
      .then(() => {
        commit("popup/close", null, {root: true});
        commit("popup/open", {
          name: "PopupEditSuccess",
          options: {
            status: state.status
          }
        }, {root: true});
        commit("clear");
      })
      .catch(errors => commit("setErrors", errors.response.data));
    commit("setPending", false);
  }
};

export const getters = {
  additions: state => state.additions,
  title: state => state.title,
  description: state => state.description,
  address: state => state.address,
  email: state => state.email,
  phone: state => state.phone,
  website: state => state.website,
  facebook: state => state.facebook,
  vk: state => state.vk,
  instagram: state => state.instagram,
  telegram: state => state.telegram,
  ok: state => state.ok,
  youtube: state => state.youtube,
  city_id: state => state.city_id,
  latitude: state => state.latitude,
  longitude: state => state.longitude,
  image: state => state.image,
  errors: state => state.errors,
  type_id: state => state.type_id,
  pending: state => state.pending,
  status: state => state.status
};
