import {Plugin} from '@nuxt/types'

interface PopupOrderOptions {
  bookingTerms: string | null,
  cancellationTerms: string | null,
  time: string,
  status: 'available' | 'active',
  tariffs: object[],
  smRegistered: boolean,
  agency: object | null,
  entity: 'events' | 'excursions',
  date: number,
  title: string,
  id: number,
}

declare module 'vue/types/vue' {
  // this.$openPopupOrder inside Vue components
  interface Vue {
    $openPopupOrder(options: PopupOrderOptions): void
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$openPopupOrder inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $openPopupOrder(options: PopupOrderOptions): void
  }

  // nuxtContext.$openPopupOrder
  interface Context {
    $openPopupOrder(options: PopupOrderOptions): void
  }
}

declare module 'vuex/types/index' {
  // this.$openPopupOrder inside Vuex stores
  interface Store<S> {
    $openPopupOrder(options: PopupOrderOptions): void
  }
}

const pluginOpenPopupOrder: Plugin = ({store, $config}, inject) => {
  inject('openPopupOrder', (options: PopupOrderOptions): void => {
    store.commit('popup/open', {
      name: 'PopupOrder',
      params: {
        style: {
          width: store.getters['viewport/sm'] || false ? '100%' : '632px',
          maxHeight: '100vh'
        },
        gradient: true
      },
      options
    })
  })
}

export default pluginOpenPopupOrder
