import { parser } from '@/utils/parser'

export const state = () => ({
  city: null,
  pending: false
})

export const mutations = {
  clear: state => state.city = null,
  setCity: (state, city) => state.city = city,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/cities?${parser(payload)}`)
      .then(resolve => commit('setCity', resolve.data[0]))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  city: state => state.city,
  pending: state => state.pending
}
