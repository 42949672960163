export const eventAppend = (state) => {
  const data = new FormData()
  if (state.title) {
    data.append('title', state.title)
  }
  if (state.annotation) {
    data.append('annotation', state.annotation)
  }
  if (state.description) {
    data.append('description', state.description)
  }
  if (state.city_id) {
    data.append('city_id', state.city_id)
  }
  if (state.label) {
    data.append('label', state.label)
  }
  if (state.youtube !== null) {
    data.append('youtube', state.youtube)
  }
  if (state.rutube !== null) {
    data.append('rutube', state.rutube)
  }
  if (state.vk !== null) {
    data.append('vk', state.vk)
  }
  if (state.started_at) {
    data.append('started_at', state.started_at)
  }
  if (state.stopped_at) {
    data.append('stopped_at', state.stopped_at)
  }
  if (state.tourism_type_id) {
    data.append('tourism_type_id', state.tourism_type_id)
  }
  if (state.category_id) {
    data.append('category_id', state.category_id)
  }
  if (state.pictures.length > 0) {
    state.pictures.forEach((item, index) => {
      data.append(`images[${index}]`, item.src); // Бек пока еще требует это поле как обязательное
      data.append(`pictures[${index}][src]`, item.src);
      data.append(`pictures[${index}][title]`, item.title || '');
      data.append(`pictures[${index}][link]`, item.link || '');
    });
  }
  if (state.agency_id) {
    data.append('agency_id', state.agency_id)
  }
  if (state.edit) {
    data.append('_method', 'PUT')
  }
  if (state.seasonsForCheckbox.filter(item => item.checked === true).length > 0) {
    state.seasonsForCheckbox.filter(item => item.checked === true).forEach((item, index) => {
      data.append(`seasons[${index}]`, item.id);
    });
  }
  if (state.additions.length > 0) {
    const additionsFiltered = state.additions.filter(item => item.key.length > 0 && item.value.length > 0)
    additionsFiltered.forEach((element, index) => {
      if (element.id.toString().includes('hash-')) {
        data.append(`additions[${index}][value]`, element.value)
        data.append(`additions[${index}][key]`, element.key)
      } else {
        data.append(`additions[${index}][value]`, element.value)
        data.append(`additions[${index}][key]`, element.key)
        data.append(`additions[${index}][id]`, element.id)
      }
    })
  } else {
    data.append("additions[]", state.additions);
  }
  data.append('place_id', state.place_id ? state.place_id : '')
  data.append('accessible_environment', state.accessible_environment ? '1' : '0')
  return data
}
