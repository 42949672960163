import {Plugin} from '@nuxt/types'
import host from "~/middleware/host";

type Payload = string | string[] | null

declare module 'vue/types/vue' {
  // this.$cardFromEntity inside Vue components
  interface Vue {
    $seoImage(payload: Payload): string
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$cardFromEntity inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $seoImage(payload: Payload): string
  }

  // nuxtContext.$cardFromEntity
  interface Context {
    $seoImage(payload: Payload): string
  }
}

declare module 'vuex/types/index' {
  // this.$cardFromEntity inside Vuex stores
  interface Store<S> {
    $seoImage(payload: Payload): string
  }
}

const pluginSeoImage: Plugin = (context, inject) => {
  inject('seoImage', (payload: Payload = null): string => {
    const hash = context.app.$randomString()
    const host = context.store.getters.host
    if (payload) {
      if (Array.isArray(payload)) {
        if (payload.length > 0) {
          return `${payload[0].replace(/micro|small|medium|large|extra?/gi, 'medium')}?v=${hash}`
        } else {
          return `${host}/meta-image-default-small.jpg?v=${hash}`
        }
      }
      if (typeof payload === 'string') {
        return `${payload.replace(/micro|small|medium|large|extra?/gi, 'medium')}?v=${hash}`
      }
      return `${host}/meta-image-default-small.jpg?v=${hash}`
    }
    return `${host}/meta-image-default-small.jpg?v=${hash}`
  })
}

export default pluginSeoImage
