import { parser } from "@/utils/parser";

export const state = () => ({
  places: [],
  city_id: null,
  query: '',
  page: 1,
  count: 12,
  categories: [],
  categoriesForCheckbox: [],
  sort: 'avg',
  tourism_type_id: null,
  promo: false,
  open_now: false,
  accessible_environment: false,
  pending: false,
  meta: null,
  lang: null,
})

export const mutations = {
  reset: (state) => {
    state.places = []
    state.meta = null
    state.query = ''
    state.page = 1
    state.city_id = null
    state.categories = null
    state.categoriesForCheckbox = state.categoriesForCheckbox.map(item => ({...item, checked: false}))
    state.accessible_environment = false
    state.sort = 'avg'
    state.promo = false
    state.open_now = false
    state.tourism_type_id = null
  },
  clear: (state) => {
    state.places = []
    state.meta = null
  },
  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setCityId: (state, city_id) => state.city_id = city_id ? Number(city_id) : null,
  setQuery: (state, query) => state.query = query,
  setPage: (state, page) => state.page = page,
  setCount: (state, count) => state.count = count,
  setPending: (state, pending) => state.pending = pending,
  setMeta: (state, meta) => state.meta = meta,
  setSort: (state, sort) => {
    sort === 'avg' || sort === 'created_at' ?
      state.sort = sort :
      state.sort = 'avg'
  },
  setLang: (state, lang) => state.lang = lang,
  setTourismTypeId: (state, tourism_type_id) => state.tourism_type_id = tourism_type_id ? Number(tourism_type_id) : null,
  setPromo: (state, promo) => {
    promo === 'true' || promo === true ?
      state.promo = true :
      state.promo = false
  },
  setOpenNow:(state, open_now) => {
    open_now === 'true' || open_now === true ?
      state.open_now = true :
      state.open_now = false
  },
  setAccessibleEnvironment: (state, accessible_environment) => {
    accessible_environment === 'true' || accessible_environment === true ?
      state.accessible_environment = true :
      state.accessible_environment = false
  },
  setCategoriesForCheckbox: (state, categoriesForCheckbox) => state.categoriesForCheckbox = categoriesForCheckbox,
  setCategoryIdFromString: (state, categoryString) => {
    if (categoryString) {
      const array = categoryString.split(',');
      array.forEach(id => {
        state.categoriesForCheckbox.forEach(item => {
          if (Number(item.id) === Number(id)) {
            item.checked = true
          }
        })
      })
    } else {
      state.categoriesForCheckbox.forEach(item => item.checked = false)
    }
  },
  setCategories: (state, categories) => {
    state.categories = categories
    // if (state.categoriesForCheckbox.length === 0) {
      state.categoriesForCheckbox = categories.map(item => ({
        id: item.id,
        title: item.title,
        checked: false
      }))
    // }
  },
}

export const actions = {
  async search({commit, state}, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/search/places?${parser({
      params: {
        city_id: state.city_id,
        query: state.query,
        count: state.count,
        page: state.page,
        resolution: 'micro',
        lang: state.lang,
      }
    })}`)
      .then((resolve) => {
        commit('setPlaces', resolve.data)
        commit('setMeta', resolve.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  },
  async places({state, commit}, payload) {
    commit('setPending', true)
    if (payload?.clear) {
      commit('clear')
    }
    await this.$axios.$get(`/api/places?${parser({
      params: {
        resolution: 'micro',
        city_id: state.city_id,
        tourism_type_id: state.tourism_type_id,
        category_id: state.categoriesForCheckbox.filter(item => item.checked === true).map(item => item.id).join(','),
        promo: state.promo,
        accessible_environment: state.accessible_environment,
        sort: state.sort,
        page: state.page,
        count: state.count,
        lang: state.lang,
      }
    })}`)
      .then(response => {
        commit('setPlaces', response.data)
        commit('setMeta', response.meta)
      })
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  places: state => state.places,
  city_id: state => state.city_id,
  query: state => state.query,
  page: state => state.page,
  count: state => state.count,
  categories: state => state.categories,
  categoriesForCheckbox: state => state.categoriesForCheckbox,
  sort: state => state.sort,
  tourism_type_id: state => state.tourism_type_id,
  promo: state => state.promo,
  open_now: state => state.open_now,
  accessible_environment: state => state.accessible_environment,
  pending: state => state.pending,
  meta: state => state.meta,
}
