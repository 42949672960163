import { parser } from '@/utils/parser'

export const state = () => ({
  places: [],
  meta: null,
  pending: false,
})

export const mutations = {
  setPlaces: (state, places) => state.places = [...state.places, ...places],
  setMeta: (state, meta) => state.meta = meta,
  setPending: (state, pending) => {
    state.pending = pending
  },
  clearPlaces: (state) => {
    state.places = []
    state.meta = null
    state.pending = false
  }
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload.clear) {
      commit('clearPlaces')
    }
    commit('setPending', true)
    await this.$axios.$get(`/api/places?${parser(payload)}&user=current`)
        .then((response) => {
          commit('setPlaces', response.data)
          commit('setMeta', response.meta)
        })
        .catch(() => null)
        .finally(() => commit('setPending', false))
  }
}

export const getters = {
  pending: state => state.pending,
  places: state => state.places,
  meta: state => state.meta
}
