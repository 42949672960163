export default {
  o(t) {
    return 360 < t ? t - 360 * Math.floor(t / 360) : t < 0 ? t + 360 * (Math.floor(-t / 360) + 1) : t
  },
  altitude({date, latitude, longitude}) {
    // let h = 33.07498100
    let h = longitude || 33.0826598
    // let a = 68.97068200
    let a = latitude || 68.9585244
    let M = 3
    const s = new Date(date);
    let t = date
    let i = null;
    let u = null;
    let r = null;
    i = s.getFullYear();
    u = s.getMonth() + 1;
    r = s.getDate();
    let P = null;
    let I = null;
    P = 367 * i - Math.floor(7 * (i + Math.floor((u + 9) / 12)) / 4) + Math.floor(275 * u / 9) + r - 730530;
    I = 282.9404 + 4.70935 * Math.pow(10, -5) * P;
    M = .016709 - 1.151 * Math.pow(10, -9) * P;
    u = this.o(356.047 + .9856002585 * P);
    t = 23.4393 - 3.563 * Math.pow(10, -7) * P;
    i = this.o(I + u);
    r = u + 180 / Math.PI * M * Math.sin(u * Math.PI / 180) * (1 + M * Math.cos(u * Math.PI / 180));
    P = Math.cos(r * Math.PI / 180) - M;
    u = Math.sin(r * Math.PI / 180) * Math.sqrt(1 - M * M);
    r = Math.sqrt(P * P + u * u);
    M = this.o(180 / Math.PI * Math.atan2(u, P) + I);
    I = P = r * Math.cos(M * Math.PI / 180);
    M = (u = r * Math.sin(M * Math.PI / 180)) * Math.cos(t * Math.PI / 180) + 0 * Math.sin(t * Math.PI / 180);
    t = u * Math.sin(t * Math.PI / 180) + 0 * Math.cos(t * Math.PI / 180);
    I = 180 / Math.PI * Math.atan2(M, I);
    r = 180 / Math.PI * Math.asin(t / r);
    h = i / 15 + 12 + (s.getUTCHours() + s.getUTCMinutes() / 60) + h / 15;
    I = this.o(15 * ((h -= 24 * Math.floor(h / 24)) - I / 15));
    P = Math.cos(I * Math.PI / 180) * Math.cos(r * Math.PI / 180);
    u = Math.sin(I * Math.PI / 180) * Math.cos(r * Math.PI / 180);
    I = Math.sin(r * Math.PI / 180);
    r = P * Math.sin(a * Math.PI / 180) - I * Math.cos(a * Math.PI / 180);
    a = P * Math.cos(a * Math.PI / 180) + I * Math.sin(a * Math.PI / 180);
    return Math.asin(a) * (180 / Math.PI)
  },
  azimuth({date, latitude, longitude}) {
    // let h = 33.07498100
    let h = longitude || 33.0826598
    // let a = 68.97068200
    let a = latitude || 68.9585244
    let M = 3
    const s = new Date(date);
    let t = date
    let i = null;
    let u = null;
    let r = null;
    i = s.getFullYear();
    u = s.getMonth() + 1;
    r = s.getDate();
    let P = null;
    let I = null;
    P = 367 * i - Math.floor(7 * (i + Math.floor((u + 9) / 12)) / 4) + Math.floor(275 * u / 9) + r - 730530;
    I = 282.9404 + 4.70935 * Math.pow(10, -5) * P;
    M = .016709 - 1.151 * Math.pow(10, -9) * P;
    u = this.o(356.047 + .9856002585 * P);
    t = 23.4393 - 3.563 * Math.pow(10, -7) * P;
    i = this.o(I + u);
    r = u + 180 / Math.PI * M * Math.sin(u * Math.PI / 180) * (1 + M * Math.cos(u * Math.PI / 180));
    P = Math.cos(r * Math.PI / 180) - M;
    u = Math.sin(r * Math.PI / 180) * Math.sqrt(1 - M * M);
    r = Math.sqrt(P * P + u * u);
    M = this.o(180 / Math.PI * Math.atan2(u, P) + I);
    I = P = r * Math.cos(M * Math.PI / 180);
    M = (u = r * Math.sin(M * Math.PI / 180)) * Math.cos(t * Math.PI / 180) + 0 * Math.sin(t * Math.PI / 180);
    t = u * Math.sin(t * Math.PI / 180) + 0 * Math.cos(t * Math.PI / 180);
    I = 180 / Math.PI * Math.atan2(M, I);
    r = 180 / Math.PI * Math.asin(t / r);
    h = i / 15 + 12 + (s.getUTCHours() + s.getUTCMinutes() / 60) + h / 15;
    I = this.o(15 * ((h -= 24 * Math.floor(h / 24)) - I / 15));
    P = Math.cos(I * Math.PI / 180) * Math.cos(r * Math.PI / 180);
    u = Math.sin(I * Math.PI / 180) * Math.cos(r * Math.PI / 180);
    I = Math.sin(r * Math.PI / 180);
    r = P * Math.sin(a * Math.PI / 180) - I * Math.cos(a * Math.PI / 180);
    a = P * Math.cos(a * Math.PI / 180) + I * Math.sin(a * Math.PI / 180);
    return this.o(Math.atan2(u, r) * (180 / Math.PI) + 180);
  }
};

