import { parser } from '@/utils/parser'

export const state = () => ({
  main: [],
  mainPending: false,
  list: [],
  sliders: []
})

export const mutations = {
  setMain: (state, main) => {
    state.main = main
  },
  setMainPending: (state, mainPending) => {
    state.mainPending = mainPending
  },
  setList: (state, list) => {
    state.list = list
  },
  setSliders: (state, sliders) => {
    state.sliders = sliders
  }
}

export const actions = {
  async main ({ commit }, payload) {
    commit('setMainPending', true)
    await this.$axios.$get(`/api/sliders?${parser(payload)}`)
      .then(async (resolve) => {
        if (resolve.data.length > 0) {
          await this.$axios.$get(`/api/sliders/${resolve.data[0].id}?${parser(payload)}`)
            .then(resolve => commit('setMain', resolve.data))
            .catch(() => null)
            .finally(() => commit('setMainPending', false))
        } else {
          commit('setMainPending', false)
        }
      })
      .catch(() => null)
  },
  async fetch ({
    state,
    commit
  }, payload) {
    await this.$axios.$get(`/api/sliders?${parser(payload)}`)
      .then(resolve => commit('setList', resolve.data))
      .then(async () => {
        const array = state.list.map(slider => this.$axios.$get(`/api/sliders/${slider.id}?${parser(payload)}`))
        const sliders = await Promise.all(array)
          .then(resolve => resolve.map(item => item.data))
        commit('setSliders', sliders)
      })
  }
}

export const getters = {
  main: state => state.main,
  sliders: state => state.sliders
}
