import { render, staticRenderFns } from "./MapNavigationPlace.vue?vue&type=template&id=8a725642&"
import script from "./MapNavigationPlace.vue?vue&type=script&lang=js&"
export * from "./MapNavigationPlace.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapNavigationBackButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/MapNavigation/MapNavigationBackButton.vue').default,Favorite: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Favorite/index.vue').default,ImageComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ImageComponent/index.vue').default,TopEntityRating: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/TopEntityRating/index.vue').default,Divider: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Divider/index.vue').default,LinkMail: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/LinkMail/index.vue').default,LinkPhone: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/LinkPhone/index.vue').default,LinkSite: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/LinkSite/index.vue').default,Button: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Button/index.vue').default})
