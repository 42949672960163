//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'PlaceCard',
  components: {
    Avatar: () => import('@/components/common/Avatar'),
    Icon24FillTrash: () => import('@/assets/icons/Icon24FillTrash.svg?inline'),
    Icon24FillPlay: () => import('@/assets/icons/Icon24FillPlay.svg?inline'),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    Icon24StrokeFavorites: () => import('@/assets/icons/Icon24StrokeFavorites.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    LabelTag: () => import('@/components/common/LabelTag'),
    RatingTag: () => import('@/components/common/RatingTag'),
    ImageComponent: () => import('@/components/common/ImageComponent'),
    Status: () => import('@/components/common/Status'),
    AudioPlayer: () => import('@/components/common/AudioPlayer')
  },
  props: {
    avatar: {
      type: String,
      default: () => ''
    },
    travel: {
      type: Boolean,
      default: () => false
    },
    full: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => null
    },
    favorited: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    audios: {
      type: Array,
      default: () => []
    },
    images: {
      type: Array,
      default: () => [require('~/assets/images/default-image.svg')]
    },
    rating: {
      type: Number,
      default: () => 0
    },
    status: {
      type: String,
      default: () => 'active'
    },
    category: {
      type: Object,
      default: () => null
    },
    editable: {
      type: Boolean,
      default: () => false
    },
    showStatus: {
      type: Boolean,
      default: () => false
    },
    place: {
      type: Object,
      default: () => null
    },
    label: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      innerFavorited: false
    }
  },
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    }),
    to() {
      switch (this.status) {
        case 'new':
          return this.localePath(this.link, this.$i18n.locale)
        case 'active':
          return this.localePath(this.link, this.$i18n.locale)
        case 'edited':
          return this.localePath(this.link, this.$i18n.locale)
        default:
          return ''
      }
    },
    is() {
      switch (this.status) {
        case 'new':
          return 'nuxt-link'
        case 'active':
          return 'nuxt-link'
        case 'edited':
          return 'nuxt-link'
        default:
          return 'div'
      }
    },
    categoryTitle() {
      return this?.category?.title || this.$t('components.place-card.category-default')
    },
    roundButtonSize() {
      switch (this?.$media()) {
        case 'hd': {
          return '38px'
        }
        default: {
          return '36px'
        }
      }
    },
    link() {
      return `/${this?.entity}/${this?.id}`
    },
    image() {
      return this.images[0] || require('~/assets/images/default-image.svg')
    },
    audio() {
      return this.audios[0]
    }
  },
  created() {
    this.innerFavorited = this.favorited
  },
  methods: {
    toggleFavorite() {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites${this.$store.getters['user/apple']
          ? '?api_token=' + this.$store.getters['user/token']
          : ''}`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited
            this.$store.dispatch('favorites/fetch', {
              entity: 'places',
              params: {
                count: 100500,
                lang: this.$i18n.locale
              }
            })
          })
      } else {
        this.$login()
      }
    },
    openPopup() {
      this.$store.commit('popup/open', {
        name: 'PopupAddPlace',
        params: {
          style: {
            width: this?.$media() === 'sm' ? '100%' : '632px',
            maxHeight: '100vh'
          }
        },
        options: this?.place
      })
    },
    remove() {
      this.$axios.$post(`api/places/${this.id}/travels/${this.$route.params.id}/remove`)
        .then(() => {
          this.$store.dispatch('travel/fetch', {
            id: this.$route.params.id,
            params: {
              lang: this.$i18n.locale
            }
          })
        })
    },
    openPlayer() {
      const players = document.querySelectorAll('.audio-player--card')
      players.forEach(elem => elem.style.display = 'none')
      this.$refs.player.$el.style.display = 'block'
      this.$store.commit('audio-player/setPlay', {
        active: true,
        uid: this.$refs.player._uid
      })
    }
  }
}
