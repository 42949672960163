//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "GalleryFrame",
  components: {
    SignComponent: () => import("@/components/common/SignComponent"),
    ImageComponent: () => import("@/components/common/ImageComponent"),
  },
  props: {
    galleryId: {
      type: String,
      default: () => ""
    },
    title: {
      type: String,
      default: () => ""
    },
    description: {
      type: String,
      default: () => ""
    },
    src: {
      type: String,
      default: () => ""
    },
    name: {
      type: String,
      default: () => ""
    },
    link: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    frameTitleColor() {
      return {
        color: `var(--gallery-${this.galleryId}-frame-title-color)`
      };
    },
    frameDescriptionColor() {
      return {
        color: `var(--gallery-${this.galleryId}-frame-description-color)`
      };
    }
  }
};
