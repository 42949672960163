//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TopEntityComponentSeasons",
  components: {
    Icon24FillSeasons: () => import("@/assets/icons/Icon24FillSeasons.svg?inline")
  },
  props: {
    seasons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    list() {
      return this?.seasons?.map(item => item.title).join(', ')
    }
  }
};
