//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AgencyEventSchedules',
  components: {
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    AgencySchedulesCard: () => import('@/components/common/AgencySchedulesCard')
  },
  layout: 'personal-area-agency',
  data () {
    return {
      titles: [
        this.$t('components.agency-event-schedules.title-name'),
        this.$t('components.agency-event-schedules.title-started-at'),
        this.$t('components.agency-event-schedules.title-stopped-at'),
        this.$t('components.agency-event-schedules.title-time')
        // this.$t('components.agency-event-schedules.title-status'),
      ]
    }
  },
  computed: {
    ...mapGetters({
      schedules: 'account-agency-event/schedules'
    })
  },
  created () {
    this.$store.dispatch('account-agency-event/schedules', {
      id: this.$route.params.event_id,
      clear: true,
    })
  },
  methods: {
    openPopupAddSchedule () {
      this.$store.commit('popup/open', {
        name: 'PopupAddSchedule',
        params: {
          style: {
            width: '632px',
            maxHeight: '100vh'
          }
        }
      })
    }
  }
}
