import axios from 'axios';
import {defaultMeta} from './seo.config.js'

export const globalConfig = async () => {
  const name = 'demo'
  const host = process.env.API_HOST
  if (!host) console.error('Host is not defined')

  const defaultLocale = 'ru'

  const sentryKey = process.env.SENTRY_DSN
  if (!sentryKey) console.error('Sentry key is not defined')

  const coords = [68.97068200, 33.07498100]
  const zoom = 10

  const disclaimer = false

  const singleCity = false

  const promoCode = true

  const yandex = {
    map: {
      apiKey: process.env.YANDEX_MAP_APIKEY,
    },
    metrika: {
      id: process.env.YANDEX_METRIKA_ID,
    },
    tickets: {
      key: process.env.YANDEX_TICKETS_KEY,
    },
    verification: process.env.YANDEX_VERIFICATION,
  }

  let locales = []
  await axios.get(`${host}/api/languages`)
    .then(response => locales = response.data.data.map(item => item.locale))
    .catch((error) => {
      if (error && error.response) {
        throw new Error(`${error?.response?.status}, ${error?.response?.data?.message}`)
      }
    })
  if (locales.length === 0) throw new Error('Locales is empty')

  let cities = []
  await axios.get(`${host}/api/cities`)
    .then(response => cities = response.data.data)
    .catch((error) => {
      if (error && error.response) {
        throw new Error(`${error?.response?.status}, ${error?.response?.data?.message}`)
      }
    })
  if (!cities.some(item => item.is_default)) throw new Error('You have not default city')

  return {
    coords,
    defaultLocale,
    defaultMeta: await defaultMeta(),
    disclaimer,
    host,
    locales,
    name,
    promoCode,
    sentryKey,
    singleCity,
    yandex,
    zoom,
  }
}
