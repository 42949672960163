//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SocialVideoFrameComponent",
  components: {
    VKVideo: () => import("@/components/common/VKVideo"),
    RutubeVideo: () => import("@/components/common/RutubeVideo"),
    YoutubeVideo: () => import("@/components/common/YoutubeVideo")
  },
  props: {
    youtube: {
      type: [String, null],
      default: () => null
    },
    rutube: {
      type: [String, null],
      default: () => null
    },
    vkontakte: {
      type: [String, null],
      default: () => null
    },
  },
};
