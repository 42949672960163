import SectionBillboard from './components/SectionBillboard';
import OstrovokWidgetComponent from "./components/OstrovokWidgetComponent";
import AviaKassaWidgetComponent from "./components/AviaKassaWidgetComponent";

export default {
  HomeView: {
    components: {
      SectionHeroSlider: {props: {'disable-margin-bottom': true}},
      SectionCategoryCollectionsEvents: {},
      SectionRecommendations: {},
      SectionCustomSliderLanding: {},
      // SectionWidgets: {
      //   props: {
      //     widgets: [
      //       {
      //         title: 'pages.index.tabs.tab0',
      //         component: 'OstrovokWidgetComponent',
      //         props: OstrovokWidgetComponent,
      //       },
      //       {
      //         title: 'pages.index.tabs.tab1',
      //         component: 'AviaKassaWidgetComponent',
      //         props: AviaKassaWidgetComponent,
      //       }
      //     ],
      //     theme: 'white',
      //     'disable-margin-top': true
      //   }
      // },
      SectionNews: {},
      SectionPhotobank: {},
      // SectionAstronomicalWidget: {
      //   special: true
      // },
      SectionStories: {},
      SectionJournalsShortGrid: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  SearchView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  RoutesView: {
    components: {
      TopTitleComponent: {},
      EntityFiltersWrapper: {},
      EmptyEntity: {}, // todo надо доделать логику с empty
      Routes: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  RouteView: {
    components: {
      SectionBannerAppMobile: {},
      SectionTags: {},
      SectionComments: {},
      ContextAdditions: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  PlacesView: {
    components: {
      TopTitleComponent: {},
      // RecommendationCard: {},
      EntityFiltersWrapper: {},
      Places: {},
      EmptyEntity: {}, // todo надо доделать логику с empty
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  PlaceView: {
    components: {
      SectionBannerAppMobile: {},
      SectionTags: {},
      SectionComments: {},
      ContextAdditions: {},
      ContextSchedule: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  NewsView: {
    components: {
      TopTitleComponent: {},
      EmptyEntity: {}, // todo надо доделать логику с empty
      News: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  NewView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
      Gallery: {},
      ContextAdditions: {}
    }
  },
  MunicipalityView: {
    components: {
      SectionMunicipalityTopTitle: {},
      SectionMunicipalityTopEntity: {},
      SectionMunicipalityEntityContent: {},
      SectionCustomSliderFirst: {},
      SectionCustomSliderSecond: {},
      SectionCategoriesSliders: {},
      SectionMunicipalitySliderExcursions: {},
      SectionMunicipalitySliderRoutes: {},
      SectionMunicipalityEventGrid: {},
      SectionMunicipalityAgenciesGrid: {},
      SectionMunicipalityGuidesGrid: {},
      SectionBannerOnMap: {},
      SectionMunicipalityJournals: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  JournalsView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  JournalView: {
    components: {
      // SectionAviaKassaWidgetComponent: {
      //   props: AviaKassaWidgetComponent()
      // },
      SectionBannerAppMobile: {},
      SectionComments: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  GuidesView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  GuideView: {
    components: {
      ContextAdditions: {},
      SectionBannerAppMobile: {},
      SectionComments: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  ExcursionsView: {
    components: {
      TopTitleComponent: {},
      EntityFiltersWrapper: {},
      Excursions: {},
      EmptyEntity: {}, // todo надо доделать логику с empty
      // AgenciesGrid: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  ExcursionView: {
    components: {
      SectionExcursionTopTitle: {},
      SectionExcursionTopEntity: {},
      SectionTags: {
        props: {
          entity: 'excursion'
        }
      },
      SectionExcursionEntityContent: {},
      SectionExcursionGallery: {},
      SectionExcursionOnMap: {},
      SectionSharing: {},
      SectionExcursionComments: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
      ProtectedAreasButton: {}
    }
  },
  EventsView: {
    components: {
      TopTitleComponent: {},
      EntityFiltersWrapper: {},
      Events: {},
      EmptyEntity: {}, // todo надо доделать логику с empty
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  EventView: {
    components: {
      SectionEventTopTitle: {},
      SectionEventTopEntity: {},
      SectionTags: {
        props: {
          entity: 'event'
        }
      },
      SectionEventEntityContent: {},
      SectionEventGallery: {},
      SectionSharing: {},
      SectionEventComments: {},
      SectionEventPlaceEventsSlider: {},
      SectionEventAnotherEventsSlider: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  CategoryCollectionsView: {
    components: {
      CategoryCollectionsTitle: {props: {}},
      CategoryCollectionsFilter: {props: {}},
      CategoryCollectionsGrid: {props: {}},
      SectionBannerAppMobile: {props: {}},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  ArticlesView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  TravelAgenciesView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  TravelAgencyView: {
    components: {
      ContextAdditions: {},
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  MemoView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  ErrorView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  InteractiveMapView: {
    components: {
      SectionBannerAppMobile: {},
      SectionBillboard: {props: SectionBillboard},
    }
  },
  TestScriptView: {
    SectionBillboard: {props: SectionBillboard},
  },
  LeisureView: {},
}
