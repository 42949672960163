import {parser} from '@/utils/parser'
import {featureNormalizer} from "@/utils/feature-normalizer";

export const state = () => ({
  branches: [],
  features: [],
  pending: false
});

export const mutations = {
  clear: (state) => {
    state.branches = [];
    state.features = [];
  },
  setBranches: (state, branches) => state.branches = [...state.branches, ...branches],
  setFeatures: (state, features) => state.features = [...state.features, ...features],
  setPending: (state, pending) => state.pending = pending,
};

export const actions = {
  async fetch({commit}, payload) {
    commit("setPending", true);
    if (payload?.clear) {
      commit("clear");
    }
    await this.$axios.$get(`/api/places?agency_id=${payload.id}${parser(payload)}`)
      .then((response) => {
        commit("setBranches", response.data);
        const features = response.data.map((item) => {
          return featureNormalizer(item);
        });
        commit("setFeatures", features);
      })
      .finally(() => {
        commit("setPending", false);
      });
  }
};

export const getters = {
  branches: state => state.branches,
  features: state => state.features,
  pending: state => state.pending,
};
