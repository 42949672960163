//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SignComponent",
  components: {
    LinkSite: () => import("@/components/common/LinkSite"),
  },
  props: {
    dark: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ""
    },
    link: {
      type: String,
      default: () => ""
    }
  },
};
