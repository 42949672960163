import {parser} from "~/utils/parser";

function toBoolean(value) {
  return value === "true" || value === true;
}

function toInt(value) {
  if (value) {
    return Number(value);
  } else {
    return null;
  }
}

function params(state) {
  let params = {
    accessible_environment: state.accessible_environment,
    category_id: state.category_id,
    city_id: state.city_id,
    count: state.count,
    open_now: state.open_now,
    page: state.page,
    promo: state.promo,
    sort: state.sort,
    tourism_type_id: state.tourism_type_id,
    season_id: state.season_id,
    level_id: state.level_id,
    age_restriction_id: state.age_restriction_id,
    place_id: state.place_id,
    has_tickets: state.has_tickets,
    lang: state.lang,
    resolution: "micro",
  };
  if (state.entity === "events") {
    params = {
      ...params,
      started_at: state.dates && state.dates[0] ? state.dates[0] : null,
      stopped_at: state.dates && state.dates[1] ? state.dates[1] : null,
    };
  }
  if (state.entity === "excursions") {
    params = {
      ...params,
      schedule_started_at: state.dates && state.dates[0] ? state.dates[0] : null,
      schedule_stopped_at: state.dates && state.dates[1] ? state.dates[1] : null,
    };
  }
  return params;
}

export const state = () => ({
  accessible_environment: false,
  categories: [],
  seasons: [],
  categoriesRaw: [],
  categoriesCheckBox: [],
  seasonsCheckBox: [],
  category_id: null,
  season_id: null,
  city_id: null,
  count: 12,
  entity: null,
  items: [],
  lang: null,
  meta: null,
  open_now: false,
  page: 1,
  pending: false,
  pendingSearch: false,
  promo: false,
  query: null,
  slug: null,
  sort: "avg",
  title: "",
  tourism_type_id: null,
  empty: false,
  filtersType: "filters",
  levels: [],
  level_id: null,
  age_restrictions: [],
  age_restriction_id: null,
  has_events: [],
  place_id: null,
  dates: null,
  has_tickets: false,
});

export const mutations = {
  setHasTickets: (state, has_tickets) => state.has_tickets = toBoolean(has_tickets),
  setDates: (state, dates) => state.dates = dates,
  setPlaceId: (state, place_id) => state.place_id = toInt(place_id),
  setHasEvents: (state, has_events) => state.has_events = has_events,
  setAgeRestrictionId: (state, age_restriction_id) => state.age_restriction_id = toInt(age_restriction_id),
  setAgeRestrictions: (state, age_restrictions) => state.age_restrictions = age_restrictions,
  setLevelId: (state, level_id) => state.level_id = toInt(level_id),
  setLevels: (state, levels) => state.levels = levels,
  setFiltersType: (state, filtersType) => state.filtersType = filtersType,
  setEmpty: (state, empty) => state.empty = empty,
  setCount: (state, count) => state.count = count,
  setTourismTypes: (state, tourism_type_id) => state.tourism_type_id = toInt(tourism_type_id),
  setCityId: (state, city_id) => state.city_id = toInt(city_id),
  setAccessibleEnvironment: (state, accessible_environment) => state.accessible_environment = toBoolean(accessible_environment),
  setOpenNow: (state, open_now) => state.open_now = toBoolean(open_now),
  setPromo: (state, promo) => state.promo = toBoolean(promo),
  setSort: (state, sort) => state.sort = sort,
  setQuery: (state, query) => state.query = query,
  setCategories: (state, categories) => state.categories = categories,
  setSeasons: (state, seasons) => state.seasons = seasons,
  setCategoriesRaw: (state, categoriesRaw) => state.categoriesRaw = categoriesRaw,
  setCategoriesCheckBox: (state, categoriesCheckBox) => {
    state.categoriesCheckBox = categoriesCheckBox;
    state.category_id = state.categoriesCheckBox
      .filter(item => item.checked)
      .map(item => item.id)
      .join(",");
  },
  setSeasonsCheckBox: (state, seasonsCheckBox) => {
    state.seasonsCheckBox = seasonsCheckBox;
    state.season_id = state.seasonsCheckBox
      .filter(item => item.checked)
      .map(item => item.id)
      .join(",");
  },
  createCategoriesCheckBox: state => {
    state.categoriesCheckBox = state.categories
      .filter(category => state.categoriesRaw.includes(category.id))
      .map(category => ({
        id: category.id,
        title: category.title,
        checked: true,
      }));
    state.category_id = state.categoriesRaw.join(",");
  },
  createSeasonsCheckBox: state => {
    state.seasonsCheckBox = state.seasons
      .map(season => ({
        id: season.id,
        title: season.title,
        checked: false,
      }));
  },
  clear: state => {
    state.items = [];
    state.meta = null;
    state.empty = false;
  },
  setPending: (state, pending) => state.pending = pending,
  setPendingSearch: (state, pendingSearch) => state.pendingSearch = pendingSearch,
  setTitle: (state, title) => state.title = title,
  setCategoryId: (state, category_id) => state.category_id = category_id,
  setEntity: (state, entity) => state.entity = entity,
  setSlug: (state, slug) => state.slug = slug,
  setItems: (state, items) => state.items = [...state.items, ...items],
  setLang: (state, lang) => state.lang = lang,
  setMeta: (state, meta) => state.meta = meta,
  setPage: (state, page) => state.page = page,
};

export const actions = {
  async hasEvents({commit, state}) {
    await this.$axios.$get(`/api/places?has_events&show=id,title${parser({
      params: {
        count: 100500,
        lang: state.lang
      }
    })}`)
      .then(response => commit("setHasEvents", response.data))
      .catch(() => null);
  },
  async search({state, commit}) {
    commit("setPending", true);
    commit("clear");
    await this.$axios.$get(`/api/search/${state.entity}?${parser({
      params: {
        city_id: state.city_id,
        category_id: state.category_id,
        query: state.query,
        lang: state.lang
      }
    })}`)
      .then(response => {
        commit("setItems", response.data);
        commit("setEmpty", response.data.length === 0);
        commit("setMeta", response.meta);
      })
      .catch(() => {
        commit("setItems", []);
        commit("setMeta", null);
      })
      .finally(() => commit("setPending", false));
  },
  async submit({state, commit}) {
    commit("setPending", true);
    await this.$axios.$get(`/api/${state.entity}?${parser({
      params: params(state)
    })}`)
      .then(response => {
        commit("setItems", response.data);
        commit("setEmpty", response.data.length === 0);
        commit("setMeta", response.meta);
      })
      .catch(() => {
        commit("setItems", []);
        commit("setMeta", null);
      })
      .finally(() => commit("setPending", false));
  },
  async category({commit, dispatch, state}, payload) {
    commit("setPending", true);
    if (payload.clear) {
      commit("clear");
    }
    commit("setEntity", state.entity);
    let temp = null;
    await this.$axios.$get(`/api/category-collections/${state.entity}/${state.slug}?${parser(payload)}`)
      .then(async (category) => {
        temp = category.data;
        commit("setTitle", temp.title);
        commit("setCategoriesRaw", temp.category_ids);
        commit("setCategoryId", temp.category_ids.join(","));
        await dispatch("submit");
      })
      .catch(() => null)
      .finally(() => commit("setPending", false));
  }
};

export const getters = {
  has_tickets: state => state.has_tickets,
  dates: state => state.dates,
  place_id: state => state.place_id,
  has_events: state => state.has_events,
  tourism_type_id: state => state.tourism_type_id,
  city_id: state => state.city_id,
  accessible_environment: state => state.accessible_environment,
  open_now: state => state.open_now,
  promo: state => state.promo,
  sort: state => state.sort,
  query: state => state.query,
  title: state => state.title,
  items: state => state.items,
  meta: state => state.meta,
  entity: state => state.entity,
  slug: state => state.slug,
  category_id: state => state.category_id,
  season_id: state => state.season_id,
  categories: state => state.categories,
  seasons: state => state.seasons,
  categoriesCheckBox: state => state.categoriesCheckBox,
  seasonsCheckBox: state => state.seasonsCheckBox,
  page: state => state.page,
  count: state => toInt(state?.meta?.per_page),
  last_page: state => toInt(state?.meta?.last_page),
  showNextPageButton: state => {
    if (state.meta?.last_page && state.meta?.current_page) {
      return Number(state.meta?.last_page) !== toInt(state.meta?.current_page);
    }
    return false;
  },
  pending: state => state.pending,
  empty: state => state.empty,
  filtersType: state => state.filtersType,
  levels: state => state.levels,
  level_id: state => state.level_id,
  age_restrictions: state => state.age_restrictions,
  age_restriction_id: state => state.age_restriction_id,
};
