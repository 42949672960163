import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=266664ce&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopEntityAgencyProfile: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/TopEntityAgencyProfile/index.vue').default,EntityContent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/EntityContent/index.vue').default,Section: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Section/index.vue').default,ExcursionCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ExcursionCard/index.vue').default,RouteCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/RouteCard/index.vue').default,JournalCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/JournalCard/index.vue').default,SectionAgencyBranches: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionAgencyBranches/index.vue').default,SectionComments: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionComments/index.vue').default,SectionBannerAppMobile: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionBillboard/index.vue').default,ErrorComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ErrorComponent/index.vue').default})
