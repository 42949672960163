//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import accountAgencyEventValidation from '@/middleware/account-agency-event-validation'
// import seo from '@/middleware/seo'

export default {
  name: 'AccountAgencyEventView',
  components: {
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    Gallery: () => import('@/components/common/Gallery/index'),
    Status: () => import('@/components/common/Status/index'),
    Section: () => import('@/components/common/Section/index'),
    AgencyEventSchedules: () => import('@/components/common/AgencyEventSchedules/index'),
    AgencyEventPlaces: () => import('@/components/common/AgencyEventPlaces/index')
  },
  layout: 'personal-area-agency',
  // middleware: [accountAgencyEventValidation, seo],
  middleware: [accountAgencyEventValidation],
  async fetch ({
    store,
    app
  }) {
    await store.dispatch('tourism-types/fetch', {
      params: {
        count: 100500,
        lang: app.i18n.locale
      }
    })
  },
  head () {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      event: 'account-agency-event/event',
      tourism_types: 'tourism-types/tourism_types'
    }),
    title () {
      return this?.event?.title || ''
    },
    description () {
      return this?.event?.description || ''
    },
    additions() {
      return this?.event?.additions
    },
    logotype () {
      return this?.event?.images[0] || require('~/assets/images/default-image.svg')
    },
    images () {
      return this?.event?.images?.map((item, index) => {
        return {
          id: index,
          image: item,
          title: '',
          description: ''
        }
      })
    },
    youtube () {
      return this?.event?.youtube
    },
    status () {
      return this?.event?.status
    },
    url () {
      return this.$store.state.host + this?.$route?.fullPath
    },
    tourism_type_id () {
      return this?.event?.tourism_type_id
    },
    tourism_type () {
      if (this?.tourism_types?.length > 0 && this?.tourism_type_id) {
        return this.tourism_types.find(type => type.id === this?.tourism_type_id)
      }
      return ''
    },
    started_at () {
      return this?.parseDates(this?.event?.started_at)
    },
    stopped_at () {
      return this?.parseDates(this?.event?.stopped_at)
    },
    information () {
      let array = []
      if (this?.event?.badge?.title) {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-event-type'),
          value: this?.event?.badge?.title
        }]
      }
      if (this?.tourism_type?.title) {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-tourism-type'),
          value: this?.tourism_type?.title
        }]
      }
      if (this?.event?.accessible_environment) {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-accessible-environment-title'),
          value: this.$t('pages.account-agency-event-view.information-accessible-environment-yes')
        }]
      } else {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-accessible-environment-title'),
          value: this.$t('pages.account-agency-event-view.information-accessible-environment-no')
        }]
      }
      if (this?.started_at) {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-started-at'),
          value: this?.started_at
        }]
      }
      if (this?.stopped_at) {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-stopped-at'),
          value: this?.stopped_at
        }]
      }
      if (this?.event?.cost) {
        array = [...array, {
          name: this.$t('pages.account-agency-event-view.information-cost'),
          value: this?.event?.cost
        }]
      }
      return array
    }
  },
  created () {
    this.$store.commit('layout-personal-area-agency/setButtonBackLink', `/account/agencies/${this.$route.params.agency_id}/events`)
    this.$store.commit('layout-personal-area-agency/setButtonBackTitle', this.$t('pages.account-agency-event-view.link-my-events'))
  },
  methods: {
    openPopup () {
      this.$store.commit('popup/open', {
        name: 'PopupAddEvent',
        params: {
          style: {
            width: '632px',
            maxHeight: '100vh'
          },
          gradient: true
        },
        options: this?.event
      })
    },
    parseDates (timestamp) {
      const date = new Date(timestamp * 1000)
      const years = date.getFullYear()
      const months = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      const days = date.getDate() < 9 ? '0' + date.getDate() : date.getDate()
      const hours = date.getHours() < 9 ? '0' + date.getHours() : date.getHours()
      const minutes = date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes()
      return days + '.' + months + '.' + years + ', ' + hours + ':' + minutes
    }
  }
}
