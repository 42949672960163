export default {
  buttons: [
    {
      id: 0,
      method: '$openPopupAddPlace',
      title: 'billboard.add-object'
    },
    {
      id: 1,
      link: 'https://nashsever51.ru',
      title: 'billboard.our-north',
    },
  ]
}
