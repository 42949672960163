import {errors} from '@/utils/errors'
import {placeAppend} from '@/utils/place-append'
import defaultStore from '@/utils/place-default-store-config'
import {parser} from '@/utils/parser'

export const state = () => ({
  config: {headers: {'Content-Type': 'multipart/form-data'}},
  errors: null,
  pending: false,
  facebook: null,
  vkontakte: null,
  instagram: null,
  youtube: null,
  youtube_announcement: null,
  rutube_announcement: null,
  vk: null,
  ok: null,
  website: null,
  phone: '',
  email: null,
  address: null,
  title: null,
  description: null,
  annotation: null,
  city_id: null,
  category_id: null,
  tourism_type_id: null,
  latitude: null,
  longitude: null,
  pictures: [],
  accessible_environment: false,
  work_hours: {
    day_1_open: null,
    day_1_close: null,
    day_2_open: null,
    day_2_close: null,
    day_3_open: null,
    day_3_close: null,
    day_4_open: null,
    day_4_close: null,
    day_5_open: null,
    day_5_close: null,
    day_6_open: null,
    day_6_close: null,
    day_7_open: null,
    day_7_close: null
  },
  edit: false,
  id: null,
  tourism_types: [],
  type_id: null,
  additions: [],
  agency_id: null,
})

export const mutations = {
  setAdditions: (state, additions) => {
    state.additions = additions
    state.errors = errors('additions', state.errors)
  },
  setPending: (state, pending) => (state.pending = pending),
  setId: (state, id) => {
    state.id = id
  },
  setTitle: (state, title) => {
    state.title = title
    state.errors = errors('title', state.errors)
  },
  setAddress: (state, address) => {
    state.address = address
    state.errors = errors('address', state.errors)
  },
  setWebsite: (state, website) => {
    state.website = website
    state.errors = errors('website', state.errors)
  },
  setPhone: (state, phone) => {
    state.phone = phone
    state.errors = errors('phone', state.errors)
  },
  setEmail: (state, email) => {
    state.email = email
    state.errors = errors('email', state.errors)
  },
  setFacebook: (state, facebook) => {
    state.facebook = facebook
    state.errors = errors('facebook', state.errors)
  },
  setVkontakte: (state, vkontakte) => {
    state.vkontakte = vkontakte
    state.errors = errors('vkontakte', state.errors)
  },
  setInstagram: (state, instagram) => {
    state.instagram = instagram
    state.errors = errors('instagram', state.errors)
  },
  setYoutube: (state, youtube) => {
    state.youtube = youtube
    state.errors = errors('youtube', state.errors)
  },
  setOdnoklassniki: (state, ok) => {
    state.ok = ok
    state.errors = errors('ok', state.errors)
  },
  setYoutubeAnnouncement: (state, youtube_announcement) => {
    state.youtube_announcement = youtube_announcement
    state.errors = errors('youtube_announcement', state.errors)
  },
  setRutubeAnnouncement: (state, rutube_announcement) => {
    state.rutube_announcement = rutube_announcement
    state.errors = errors('rutube_announcement', state.errors)
  },
  setVkAnnouncement: (state, vk) => {
    state.vk = vk
    state.errors = errors('vk', state.errors)
  },
  setAccessibleEnvironment: (state, accessible_environment) => {
    state.accessible_environment = accessible_environment
  },
  setAnnotation: (state, annotation) => {
    state.annotation = annotation
    state.errors = errors('annotation', state.errors)
  },
  setDescription: (state, description) => {
    state.description = description
    state.errors = errors('description', state.errors)
  },
  setCityId: (state, city_id) => {
    state.city_id = city_id
    state.errors = errors('city_id', state.errors)
  },
  setCategoryId: (state, category_id) => {
    state.category_id = category_id
    state.type_id = category_id
    state.errors = errors('category_id', state.errors)
    state.errors = errors('type_id', state.errors)
  },
  setTypeId: (state, type_id) => {
    state.type_id = type_id
    state.errors = errors('type_id', state.errors)
  },
  setTourismTypeId: (state, tourism_type_id) => {
    state.tourism_type_id = tourism_type_id
    state.errors = errors('tourism_type_id', state.errors)
  },
  setLatitude: (state, latitude) => {
    state.latitude = latitude
    state.errors = errors('latitude', state.errors)
  },
  setLongitude: (state, longitude) => {
    state.longitude = longitude
    state.errors = errors('longitude', state.errors)
  },
  setWorkHours: (state, work_hours) => {
    state.errors = errors('work_hours', state.errors)
    state.work_hours = {
      day_1_open: work_hours.day_1_open,
      day_1_close: work_hours.day_1_close,
      day_2_open: work_hours.day_2_open,
      day_2_close: work_hours.day_2_close,
      day_3_open: work_hours.day_3_open,
      day_3_close: work_hours.day_3_close,
      day_4_open: work_hours.day_4_open,
      day_4_close: work_hours.day_4_close,
      day_5_open: work_hours.day_5_open,
      day_5_close: work_hours.day_5_close,
      day_6_open: work_hours.day_6_open,
      day_6_close: work_hours.day_6_close,
      day_7_open: work_hours.day_7_open,
      day_7_close: work_hours.day_7_close
    }
  },
  setErrors: (state, errors) => state.errors = {...state.errors, ...errors},
  deleteErrorsByKey: (state, key) => state.errors = errors(key, state.errors),
  setEdit: (state, {
    edit,
    id
  }) => {
    state.edit = !!(edit || id)
  },
  setPictures: (state, pictures) => {
    state.pictures = pictures
    state.errors = errors('pictures', state.errors)
    state.errors = errors('images', state.errors)
  },
  setTourismTypes: (state, tourism_types) => state.tourism_types = tourism_types,
  setAgencyId: (state, agency_id) => state.agency_id = agency_id,
  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key]
    }
  }
}

export const actions = {
  async post({state, commit}) {
    commit('setPending', true)
    const data = placeAppend(state)
    await this.$axios.$post('/api/places', data, state.config)
      .catch(errors => commit('setErrors', errors.response.data))
    commit('setPending', false)
  },
  async put({state, commit}, payload) {
    commit('setPending', true)
    const data = placeAppend(state)
    await this.$axios.$post(`/api/places/${payload.id}`, data, state.config)
      .catch(errors => commit('setErrors', errors.response.data))
    commit('setPending', false)
  },
  async tourism_types({commit}, payload) {
    const tourism_types = await this.$axios.$get(`/api/tourism_types?${parser(payload)}`)
    commit('setTourismTypes', tourism_types.data)
  },
}

export const getters = {
  agency_id: state => state.agency_id,
  additions: state => state.additions,
  errors: state => state.errors,
  pending: state => state.pending,
  facebook: state => state.facebook,
  vkontakte: state => state.vkontakte,
  instagram: state => state.instagram,
  youtube: state => state.youtube,
  youtube_announcement: state => state.youtube_announcement,
  rutube_announcement: state => state.rutube_announcement,
  vk: state => state.vk,
  ok: state => state.ok,
  website: state => state.website,
  phone: state => state.phone,
  email: state => state.email,
  address: state => state.address,
  title: state => state.title,
  description: state => state.description,
  annotation: state => state.annotation,
  city_id: state => state.city_id,
  category_id: state => state.category_id,
  tourism_type_id: state => state.tourism_type_id,
  latitude: state => state.latitude,
  longitude: state => state.longitude,
  work_hours: state => state.work_hours,
  pictures: state => state.pictures,
  accessible_environment: state => state.accessible_environment,
  edit: state => state.edit,
  tourism_types: state => state.tourism_types,
  id: state => state.id,
  type_id: state => state.type_id,
  notEmpty: state => {
    return (state.title !== null && state.title?.length > 0) ||
      (state.annotation !== null && state.annotation?.length > 0) ||
      (state.description !== null && state.description?.length > 0) ||
      state.category_id !== null ||
      state.tourism_type_id !== null ||
      state.pictures.length > 0 ||
      state.additions.length > 0 ||
      state.accessible_environment ||
      (state.website !== null && state.website?.length > 0) ||
      (state.phone !== null && state.phone?.length > 0) ||
      (state.email !== null && state.email?.length > 0) ||
      (state.vk !== null && state.vk?.length > 0) ||
      (state.ok !== null && state.ok?.length > 0) ||
      (state.youtube !== null && state.youtube?.length > 0) ||
      (state.youtube_announcement !== null && state.youtube_announcement?.length > 0) ||
      (state.rutube_announcement !== null && state.rutube_announcement?.length > 0) ||
      (state.vk !== null && state.vk?.length > 0) ||
      Object.values(state.work_hours).filter(item => item !== null && item.length > 0).length > 0 ||
      state.agency_id !== null
  }
}
