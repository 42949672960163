import axios from 'axios'

export const defaultMeta = async () => {
  let description = ''
  let image = '/meta-image-default-small'
  let keywords = ''
  const locale = ''
  const site_name = ''
  let title = ''
  const type = 'website'
  const url = ''

  await axios.get(`${process.env.API_HOST}/api/metadata/index`)
    .then(response => {
      description = response.data.data.description ? response.data.data.description : description
      image = response.data.data.image ? response.data.data.image : image
      keywords = response.data.data.keywords ? response.data.data.keywords : keywords
      title = response.data.data.title ? response.data.data.title : title
    })
    .catch(error => {
      throw new Error(error.message)
    })

  return {
    description,
    image,
    keywords,
    locale,
    site_name,
    title,
    type,
    url,
  }
}
