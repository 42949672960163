import { render, staticRenderFns } from "./index.vue?vue&type=template&id=b9a6e7b4&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopEntityAgencyProfile: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/TopEntityAgencyProfile/index.vue').default,Section: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Section/index.vue').default,TradingPlatformMemberBecome: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/TradingPlatformMemberBecome/index.vue').default,ContextAdditions: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ContextAdditions/index.vue').default,RoundButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/RoundButton/index.vue').default,PlaceCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/PlaceCard/index.vue').default,PendingButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/PendingButton/index.vue').default,Button: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Button/index.vue').default,Empty: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Empty/index.vue').default,Tabs: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Tabs/index.vue').default,SectionAgencyLegalInformation: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionAgencyLegalInformation/index.vue').default})
