//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seo from '~/middleware/seo'

export default {
  name: 'ErrorView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    Section: () => import('@/components/common/Section'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
  },
  middleware: [seo],
  props: {
    error: {
      type: Object,
      default: () => null
    }
  },
  head() {
    return {
      title: this.$t('pages.error.seo.title'),
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.$t('pages.error.seo.title'),
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.error.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    components() {
      return this.$config?.pages?.ErrorView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    status() {
      return this?.error?.statusCode || 404
    },
    message() {
      return this?.error?.message || ''
    },
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
    })
  },
}
