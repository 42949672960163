import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4ca72b46&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Header/index.vue').default,Footer: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Footer/index.vue').default,MobileMenu: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/MobileMenu/index.vue').default,Popup: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Popup/index.vue').default,Disclaimer: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Disclaimer/index.vue').default,ScrollToTopButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ScrollToTopButton/index.vue').default})
