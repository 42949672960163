//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import {mapGetters} from "vuex";

export default {
  name: "OnMap",
  props: {
    area: {
      type: [Object, Array],
      default: () => null
    },
    mapId: {
      type: String,
      default: () => "map"
    },
    width: {
      type: String,
      default: () => "100%"
    },
    height: {
      type: String,
      default: () => "100%"
    },
    places: {
      type: Array,
      default: () => []
    },
    routes: {
      type: Array,
      default: () => []
    },
    coords: {
      type: Array,
      default: () => [0, 0]
    },
    zoom: {
      type: Number,
      default: () => 0
    },
    buttonsZoom: {
      type: Boolean,
      default: () => false
    },
    canChangeRoute: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      toggle: false,
      map: null,
    };
  },
  computed: {
    ...mapGetters({
      categories: "categories/places",
      cities: "cities/cities",
    }),
    style() {
      return {
        width: this.width,
        height: this.height,
      };
    }
  },
  watch: {
    toggle(n) {
      if (n === true) {
        this.map.geoObjects.add(new ymaps.Placemark([0, 0], {}, {}));
      } else {
        this.map.geoObjects.removeAll();
      }
    },
  },
  mounted() {
    ymaps.ready(this.init);
  },
  methods: {
    init() {
      this.map = new ymaps.Map(this.mapId, {
          center: this.coords,
          controls: [],
          zoom: 10
        },
        // todo: Тут автоматическое изменение размеры карты от контейнера
        {
          autoFitToViewport: "always"
        },
        {
          yandexMapDisablePoiInteractivity: true
        });

      setTimeout(() => {
        if (this.places.length > 0) {
          this.showPlacesOnMap();
        }

        if (this.routes.length > 0) {
          this.showRouteOnMap();
        }

        if (this.area) {
          this.showAreaOnMap();
        }

        this.$watch("places", (o, n) => {
          if (JSON.stringify(o) !== JSON.stringify(n)) {
            if (this.places.length > 0) {
              this.showPlacesOnMap();
            }
          }
        });

        this.$watch("routes", (o, n) => {
          if (JSON.stringify(o) !== JSON.stringify(n)) {
            if (this.routes.length > 0) {
              this.showRouteOnMap();
            }
          }
        });

        this.$watch("area", (o, n) => {
          if (JSON.stringify(o) !== JSON.stringify(n)) {
            if (this.area) {
              this.showAreaOnMap();
            }
          }
        });
      }, 1);

      if (this.buttonsZoom) {
        this.map.controls.add(this.zoomButtons());
      }
    },
    showAreaOnMap() {
      this.map.geoObjects.removeAll();
      if (this.area?.features?.length > 0) {
        this.area.features.forEach((feature) => {
          const properties = feature.properties;
          const balloonLayoutTemplate = `
            <div class="on-map__balloon">
            <div class="on-map__balloon-wrapper">
              <div class="on-map__balloon-close">
                <svg class="on-map__balloon-close-icon on-map__balloon-close-icon--areas" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0015 11.2721L18.5888 4.68481L20.003 6.09903L13.4157 12.6863L20.003 19.2736L18.5888 20.6878L12.0015 14.1005L5.41422 20.6878L4 19.2736L10.5873 12.6863L4 6.09903L5.41421 4.68481L12.0015 11.2721Z" />
                </svg>
              </div>
              <div class="on-map__balloon-meta">
                <h4>${properties.title || ""}</h4>
                <p class="caption">${properties.description || ""}</p>
              </div>
            </div>
            </div>
          `;
          const balloonLayout = ymaps.templateLayoutFactory.createClass(balloonLayoutTemplate, {
            build() {
              balloonLayout.superclass.build.call(this);
              this._$element = document.querySelector(".on-map__balloon");
              this._$element.querySelector(".on-map__balloon-close")
                .addEventListener("click", this.onCloseClick.bind(this));
            },
            getShape() {
              if (!this._$element) {
                return balloonLayout.superclass.getShape.call(this);
              }
              const position = {
                top: this._$element.offsetTop,
                left: this._$element.offsetLeft
              };
              return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                [position.left, position.top], [
                  position.left + this._$element.offsetWidth,
                  position.top + this._$element.offsetHeight
                ]
              ]));
            },
            onCloseClick(e) {
              e.preventDefault();
              this.events.fire("userclose");
              this._data.map.setBounds(this._data.map.geoObjects.getBounds());
            },
            clear() {
              balloonLayout.superclass.clear.call(this);
            }
          });
          feature.geometry.coordinates.forEach(() => {
            if (feature.geometry.type === "MultiPolygon") {
              feature.geometry.coordinates.forEach(coordinates => {
                const polygon = new ymaps.Polygon(
                  coordinates, {
                    hintContent: properties.title,
                  }, {
                    balloonLayout,
                    fillColor: properties.color + "20",
                    strokeColor: properties.color,
                    strokeWidth: 1,
                  });
                polygon.events.add(["balloonopen"], function () {
                  this.map.setBounds(polygon.geometry.getBounds());
                }.bind(this));
                this.map.geoObjects.add(polygon);
              });
            }
            if (feature.geometry.type === "Polygon") {
              const polygon = new ymaps.Polygon(
                feature.geometry.coordinates, {
                  hintContent: properties.title,
                }, {
                  balloonLayout,
                  fillColor: properties.color + "20",
                  strokeColor: properties.color,
                  strokeWidth: 1,
                });
              polygon.events.add(["balloonopen"], function () {
                this.map.setBounds(polygon.geometry.getBounds());
              }.bind(this));
              this.map.geoObjects.add(polygon);
            }
          });
        });
        this?.map?.setBounds(this?.map?.geoObjects?.getBounds());
      }
    },
    showPlacesOnMap() {
      const coords = this?.places[0]?.geometry?.coordinates || this.coords;
      ymaps.geocode([coords[1], coords[0]])
        .then((res) => {
          const place = res.geoObjects.get(0);
          const zoom = (place.getPremiseNumber() || place.getThoroughfare()) ? 16 : 12;
          this.map.setCenter([coords[1], coords[0]], zoom);
        })
        .then(() => {
          this?.map?.geoObjects?.removeAll();

          this.places.forEach((place) => {
            const coordinates = [place.geometry.coordinates[1], place.geometry.coordinates[0]];
            const icon = this?.categories?.find(item => item.id === place.properties.category_id)?.image
              ? {
                iconLayout: "default#image",
                iconImageHref: this?.categories?.find(item => item.id === place.properties.category_id)?.image,
                iconImageSize: [28, 28],
                iconImageOffset: [-14, -14],
                hideIconOnBalloonOpen: false,
                balloonLayout: this.balloonLayout(place)
              }
              : {
                iconLayout: "default#imageWithContent",
                iconImageHref: this?.categories?.find(item => item.id === place.properties.category_id)?.image,
                iconImageSize: [28, 28],
                iconImageOffset: [-14, -14],
                iconContentOffset: [0, 0],
                iconContentLayout: ymaps.templateLayoutFactory.createClass(
                  "<div class=\"on-map__placemark\"></div>"
                ),
                hideIconOnBalloonOpen: false,
                balloonLayout: this.balloonLayout(place)
              };
            const placemark = new ymaps.Placemark(coordinates, null, icon);

            // placemark.events.add('click', function (e) {
            //   if (this.canChangeRoute) {
            //     e.preventDefault()
            //     this.$router.replace({
            //       query: {
            //         id: place.id,
            //         entity: 'place'
            //       }
            //     })
            //     this.$store.commit('interactive-map/setId', place.id)
            //     this.$store.commit('interactive-map/setCurrentTab', 'place')
            //   }
            // }.bind(this))

            this?.map?.geoObjects?.add(placemark);
          });

          if (this.places.length > 1) {
            this?.map?.setBounds(this?.map?.geoObjects?.getBounds());
          }
        });
    },
    drawPolylines(features) {
      const markerPoints = [];
      let polylinePoints = null;
      for (const feature of features) {
        if (feature.geometry.type === "Point") {
          markerPoints.push(feature);
        }
        if (feature.geometry.type === "LineString") {
          polylinePoints = this.$route.params.id
            ? feature.geometry.coordinates
            : feature.geometry.coordinates.map(coordinate =>
              [coordinate[1], coordinate[0]]);
        }
      }
      const polyline = new ymaps.Polyline(polylinePoints, {}, {
        strokeColor: "#2F80ED",
        strokeWidth: 4,
        strokeOpacity: 0.7
      });
      markerPoints.forEach((item, index) => {
        const coordinates = [item.geometry.coordinates[1], item.geometry.coordinates[0]];
        const placemark = new ymaps.Placemark(coordinates, {}, {
          iconLayout: "default#imageWithContent",
          iconImageSize: [28, 28],
          iconImageOffset: [-14, -14],
          iconContentOffset: [0, 0],
          hideIconOnBalloonOpen: false,
          iconContentLayout: ymaps.templateLayoutFactory.createClass(
            `<div class="on-map__placemark">${index + 1}</div>`
          ),
          balloonLayout: this.balloonLayout(item)
        });
        this?.map?.geoObjects?.add(placemark);
      });
      this?.map?.geoObjects?.add(polyline);
      this?.map?.setBounds(this?.map?.geoObjects?.getBounds());
    },
    drawMultiRoute(features) {
      const multiRoute = new ymaps.multiRouter.MultiRoute({
        referencePoints: features.map(feature =>
          [feature.geometry.coordinates[1], feature.geometry.coordinates[0]])
      }, {
        boundsAutoApply: true,
        routeStrokeWidth: 4,
        routeStrokeColor: "#2F80ED",
        routeStrokeOpacity: 0.7,
        routeActiveStrokeWidth: 4,
        routeActiveStrokeColor: "#2F80ED",
        routeActiveStrokeOpacity: 0.7
      });

      features.forEach((item, index) => {
        multiRoute.model.events.once("requestsuccess", () => {
          const yandexWayPoint = multiRoute.getWayPoints()
            .get(index);
          ymaps.geoObject.addon.balloon.get(yandexWayPoint);
          yandexWayPoint.options.set({
            iconLayout: "default#imageWithContent",
            iconImageSize: [28, 28],
            iconImageOffset: [-14, -14],
            iconContentOffset: [0, 0],
            hideIconOnBalloonOpen: false,
            iconContentLayout: ymaps.templateLayoutFactory.createClass(
              `<div class="on-map__placemark">${index + 1}</div>`
            ),
            balloonLayout: this.balloonLayout(item)
          });
        });
      });
      this?.map?.geoObjects?.add(multiRoute);
    },
    balloonLayout(item) {
      const category = this?.categories?.find(category => category.id === item.properties.category_id);
      const myBalloonLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="on-map__balloon">
            <div class="on-map__balloon-leg"></div>
            <div class="on-map__balloon-wrapper">
              <a class="on-map__balloon-link" href="/places/${item?.id}">
                <div class="on-map__balloon-close">
                <svg class="on-map__balloon-close-icon on-map__balloon-close-icon--others" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.0015 11.2721L18.5888 4.68481L20.003 6.09903L13.4157 12.6863L20.003 19.2736L18.5888 20.6878L12.0015 14.1005L5.41422 20.6878L4 19.2736L10.5873 12.6863L4 6.09903L5.41421 4.68481L12.0015 11.2721Z" />
                </svg>
                </div>
                <img class="on-map__balloon-image" src="${this.image(item?.properties?.image)}">
                <div class="on-map__balloon-meta">
                  <h6>${category?.title || ""}</h6>
                  <h4>${item?.properties?.balloonContent?.title || ""}</h4>
                  <p>${item?.properties?.balloonContent?.address || ""}</p>
                </div>
              </a>
            </div>
        </div>`, {
          build() {
            myBalloonLayout.superclass.build.call(this);
            this._$element = document.querySelector(".on-map__balloon");
            this._$element.querySelector(".on-map__balloon-close")
              .addEventListener("click", this.onCloseClick.bind(this));
          },
          getShape() {
            if (!this._$element) {
              return myBalloonLayout.superclass.getShape.call(this);
            }
            const position = {
              top: this._$element.offsetTop,
              left: this._$element.offsetLeft
            };
            return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
              [position.left, position.top], [
                position.left + this._$element.offsetWidth,
                position.top + this._$element.offsetHeight
              ]
            ]));
          },
          onCloseClick(e) {
            e.preventDefault();
            this.events.fire("userclose");
          },
          clear() {
            myBalloonLayout.superclass.clear.call(this);
          }
        });
      return myBalloonLayout;
    },
    showRouteOnMap() {
      this?.map?.geoObjects?.removeAll();
      let lineString = false;
      this.routes.forEach((item) => {
        lineString = item.geometry.type === "LineString";
      });
      lineString
        ? this.drawPolylines(this.routes)
        : this.drawMultiRoute(this.routes);
    },
    image(url) {
      return url.replace(/micro|small|medium|large|extra?/gi, "small");
    },
    zoomButtons() {
      const ZoomLayout = ymaps.templateLayoutFactory.createClass(
        `<div id="tourism-zoom-map-buttons" class='on-map__zoom-buttons'>
            <div id='zoom-in' class='on-map__zoom-button'>
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M11 13V20H13V13H20V11L13 11V4H11V11L4 11V13H11Z" />
               </svg>
            </div>
            <div id='zoom-out' class='on-map__zoom-button'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13L6 13L6 11L18 11V13Z" />
                </svg>
            </div>
        </div>`, {
          build() {
            ZoomLayout.superclass.build.call(this);
            this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
            this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);
            const btnZoomIn = document.querySelector("#zoom-in");
            const btnZoomOut = document.querySelector("#zoom-out");
            btnZoomIn.addEventListener("click", this.zoomInCallback.bind(this));
            btnZoomOut.addEventListener("click", this.zoomOutCallback.bind(this));
          },
          clear() {
            const btnZoomIn = document.querySelector("#zoom-in");
            const btnZoomOut = document.querySelector("#zoom-out");
            btnZoomIn.addEventListener("click", this.zoomInCallback.unbind(this));
            btnZoomOut.addEventListener("click", this.zoomInCallback.unbind(this));
            ZoomLayout.superclass.clear.call(this);
          },
          zoomIn() {
            const map = this.getData().control.getMap();
            map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
          },
          zoomOut() {
            const map = this.getData().control.getMap();
            map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
          }
        });
      return new ymaps.control.ZoomControl({options: {layout: ZoomLayout}});
    }
  },
};
