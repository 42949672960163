import { render, staticRenderFns } from "./index.vue?vue&type=template&id=cc5c45be&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/InputComponent/index.vue').default,SelectComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SelectComponent/index.vue').default,DatePickerComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/DatePickerComponent/index.vue').default,Button: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Button/index.vue').default,AccountAgencyOrder: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/AccountAgencyOrder/index.vue').default,RoundButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/RoundButton/index.vue').default,Empty: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Empty/index.vue').default,Divider: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Divider/index.vue').default})
