//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import scheduleCalendar from '@/mixins/schedule-calendar'

export default {
  name: 'AgencySchedulesCard',
  components: {
    Icon24FillTrash: () => import('@/assets/icons/Icon24FillTrash.svg?inline'),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    Icon24FillArrowBottom: () => import('@/assets/icons/Icon24FillArrowBottom.svg?inline'),
    Hint: () => import('@/components/common/Hint'),
    Calendar: () => import('@/components/common/Calendar'),
    StatusDropDownMenu: () => import('@/components/common/StatusDropDownMenu')
    // ScheduleWeekdays: () => import('@/components/ScheduleWeekdays/index')
  },
  mixins: [scheduleCalendar],
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    entity: {
      type: String,
      default: () => ''
    },
    time: {
      type: String,
      default: () => ''
    },
    days: {
      type: Array,
      default: () => []
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    started_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    stopped_at: {
      type: Number,
      default: () => 0
    },
    // eslint-disable-next-line vue/require-default-prop,vue/prop-name-casing
    tariff_title: {
      type: String,
      default: () => ''
    },
    price: {
      type: String,
      default: () => ''
    },
    quota: {
      type: Number,
      default: () => 0
    },
    status: {
      type: String,
      default: () => ''
    },
    bonus: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      scheduleItem: null,
      showMenu: false,
      showInfo: false
    }
  },
  computed: {
    ...mapGetters({
      submit: ['popup/submit'],
      params: ['popup/params']
    }),
    parseTime () {
      return this.$moment(this.time, 'HH:mm:ss')
        .format('HH:mm')
    },
    parsePrice () {
      return this?.price ? this?.price?.split('.')[0] + ' ₽' : '-'
    },
    scheduleId () {
      return this?.params?.id
    }
  },
  watch: {
    submit (val) {
      if (val && this.scheduleId === this.id) {
        this.delete()
      }
    }
  },
  mounted () {
    this.getSchedules()
    this.scheduleItem = {
      id: this?.id,
      started_at: this?.$moment.unix(this?.started_at)
        .format('YYYY-MM-DD'),
      stopped_at: this?.$moment.unix(this?.stopped_at)
        .format('YYYY-MM-DD'),
      time: this?.parseTime,
      days: this?.days,
      tariff_title: this?.tariff_title,
      price: this?.price?.split('.')[0],
      quota: this?.quota?.toString(),
      status: this?.status,
      max_bonus_points: this?.bonus
    }
  },
  methods: {
    remove () {
      const entityId = this.$route.params.event_id || this.$route.params.excursion_id
      const entity = this.$route.params.event_id
        ? 'events'
        : this.$route.params.excursion_id
          ? 'excursions'
          : ''
      this.$store.commit('popup/open', {
        name: 'PopupRemoveSchedule',
        options: {
          entityId,
          entity,
          id: this?.id
        }
      })
    },
    closeMenu () {
      this.showMenu = false
    },
    toggleInfo () {
      this.showInfo = !this.showInfo
    },
    openPopupScheduleQuotaEdit (schedule) {
      this.$store.commit('popup/open', {
        name: 'PopupScheduleQuotaEdit',
        options: {
          id: this.$route.params.excursion_id || this.$route.params.event_id,
          entity: this.entity,
          schedule
        }
      })
    },
    openPopupEditSchedule () {
      this.$store.commit('popup/open', {
        name: 'PopupAddSchedule',
        params: {
          style: {
            width: '632px',
            maxHeight: '100vh'
          }
        },
        options: this?.scheduleItem
      })
    },
    fetchTrip () {
      this.$store.dispatch('excursion/fetch', {
        id: this.$route.params.excursion_id,
        params: {
          lang: this.$i18n.locale
        }
      })
    },
    fetchEvent () {
      this.$store.dispatch('event/fetch', {
        id: this.$route.params.event_id,
        params: {
          lang: this.$i18n.locale
        }
      })
    },
    delete () {
      if (this.$route.params.event_id) {
        this.$axios.$delete(`/api/events/${this.$route.params.event_id}/schedules/${this.scheduleId}`)
          .then(() => {
            this.fetchEvent()
            this.$store.commit('popup/close')
            this.$store.dispatch('account-agency-event/schedules', {
              id: this.$route.params.event_id,
              clear: true
            })
          })
      }
      if (this.$route.params.excursion_id) {
        this.$axios.$delete(`/api/excursions/${this.$route.params.excursion_id}/schedules/${this.scheduleId}`)
          .then(() => {
            this.fetchTrip()
            this.$store.commit('popup/close')
            this.$store.dispatch('account-agency-excursion/schedules', {
              id: this.$route.params.excursion_id,
              clear: true
            })
          })
      }
    },
    statusList (status) {
      switch (status) {
        case 'new': {
          return ['active']
        }
        case 'active': {
          return ['nonactive', 'available']
        }
        case 'nonactive': {
          return ['active']
        }
        case 'available': {
          return ['active']
        }
      }
    },
    changeStatus (status) {
      this.$store.commit('popup/open', {
        name: 'PopupTariffStatusChange',
        options: {
          id: this.id,
          status
        },
      })
    },
    showHint (schedule) {
      return schedule[this.dayMonthYear(schedule)].length > 0
    },
    async getSchedules () {
      const entityId = this.$route.params.event_id || this.$route.params.excursion_id
      const entity = this.$route.params.excursion_id ? 'excursions' : 'events'
      this.schedules = Promise.all([
        await this.$axios.get(`/api/${entity}/${entityId}/schedules-calendar/${this.id}?month=${this.currentNumberMonth}`),
        await this.$axios.get(`/api/${entity}/${entityId}/schedules-calendar/${this.id}?month=${this.currentNumberMonthPlusOneMonth}`)
      ])
        .then(resolve => this.schedules = resolve.map(item => item.data.data))
        .then(() => {
          if (this.schedules.flat().length > 0) {
            this.showCalendar = true
          }
        })
    },
    daysOfCurrentMonth ({ month, index }) {
      let array = []
      // Собираем в новый массив дни с шедулес
      for (const key in this.schedules[index]) {
        const item = {}
        item[key] = this.schedules[index][key]
        array = [...array, item]
      }
      // Месяц болванка
      const week = this.weeksOfCurrentMonth({ month })
        .flat(2)
      // Перебираем и сравниваем по ключам массив шедулес и месяца болванки, при совпадении ключей, в месяц болванку записывается шедуль
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < week.length; j++) {
          if (Object.keys(week[j])[0] === Object.keys(array[i])[0]) {
            week[j] = array[i]
          }
        }
      }
      // Недели месяца с содержанием шедулес
      return week
    },
    classList ({
      schedule,
      currentMonth
    }) {
      return [
        this.dayMonthYear(schedule) === this.$moment()
          .format('DD-MM-YYYY')
          ? 'calendar__day--current'
          : '',
        schedule[this.dayMonthYear(schedule)][0]?.status
          ? `calendar__day--${schedule[this.dayMonthYear(schedule)][0]?.status}`
          : '',
        this.isCurrentMonth({
          date: this.dayMonthYear(schedule),
          currentMonth
        })
          ? 'calendar__day--not-current-month'
          : ''
      ]
    }
  }
}
