import { render, staticRenderFns } from "./_excursion_id.vue?vue&type=template&id=3f91a4cc&"
import script from "./_excursion_id.vue?vue&type=script&lang=js&"
export * from "./_excursion_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Status: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Status/index.vue').default,RoundButton: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/RoundButton/index.vue').default,Gallery: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Gallery/index.vue').default,ContextAdditions: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/ContextAdditions/index.vue').default,Section: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Section/index.vue').default,AgencyExcursionPlaces: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/AgencyExcursionPlaces/index.vue').default,AgencyExcursionSchedules: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/AgencyExcursionSchedules/index.vue').default})
