// Паттерн на проверку видео с Вконтакте. Если значение соответсвует - true, иначе - false.

import {Plugin} from '@nuxt/types'

type VkVideo = string | '';

declare module 'vue/types/vue' {
  interface Vue {
    $isVkVideo(video: VkVideo): boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isVkVideo(video: VkVideo): boolean
  }

  interface Context {
    $isVkVideo(video: VkVideo): boolean
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $isVkVideo(video: VkVideo): boolean
  }
}

const pluginIsVkVideo: Plugin = (context, inject) => {
  inject('isVkVideo', (video: VkVideo) => {
    const regexpVkVideo = /^https:\/\/vk.com\/video-?\d+_\d+$/
    return regexpVkVideo.test(video)
  })
}

export default pluginIsVkVideo
