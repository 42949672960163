export default {
  config: { headers: { 'Content-Type': 'multipart/form-data' } },
  errors: null,
  pending: false,
  tourism_type_id: null,
  category_id: null,
  agency_id: null,
  city_id: null,
  place_id: null,
  place: null,
  places: [],
  title: null,
  annotation: null,
  description: null,
  started_at: null,
  stopped_at: null,
  youtube: null,
  rutube: null,
  vk: null,
  label: null,
  pictures: [],
  accessible_environment: false,
  edit: false,
  id: null,
  additions: [],
  seasonsForCheckbox: [],
}
