import { parser } from '@/utils/parser'

export const state = () => ({
  categories: []
})

export const mutations = {
  setPlaceTypeCategories: (state, categories) => state.categories = categories
}

export const actions = {
  fetch ({ commit }, payload) {
    if (payload?.clear) {
      commit('setPlaceTypeCategories', [])
    }
    this.$axios.$get(`/api/place_type_categories?${parser(payload)}`)
      .then(async (resolve) => {
        if (resolve?.data?.length > 0) {
          const modifiedCategories = await Promise.all(resolve.data.map(async (item) => {
            item.places = (await Promise.all(item.place_types.map(async (item) => {
              return await this.$axios.$get(`/api/places?${parser({
                params: {
                  resolution: payload.params.resolution,
                  count: payload.params.count,
                  category_id: item.id,
                  city_id: payload.params.city_id,
                  lang: payload.params.lang
                }
              })}`)
                .then(resolve => resolve.data)
            }))).flat(2)
            return item
          }))
          commit('setPlaceTypeCategories', modifiedCategories.filter(item => item?.places?.length > 0))
        } else {
          commit('setPlaceTypeCategories', [])
        }
      })
  }
}

export const getters = {
  categories: state => state.categories
}
