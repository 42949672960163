import {parser} from '@/utils/parser'

export const state = () => ({
  excursion: null,
  pending: false,
})

export const mutations = {
  setExcursion: (state, excursion) => state.excursion = excursion,
  setPending: (state, pending) => state.pending = pending,
}

export const actions = {
  async fetch({commit}, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/excursions/${payload.id}?${parser(payload)}`)
      .then(response => commit('setExcursion', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  excursion: state => state.excursion,
  pending: state => state.pending,
  protectedAreas: state => state.excursion?.protected_areas || false,
  accessibleEnvironment: state => state.excursion?.accessible_environment || false,
  additions: state => state.excursion?.additions || [],
  ageRestriction: state => state.excursion?.age_restriction || 0,
  agency: state => state.excursion?.agency || null,
  audios: state => state.excursion?.audios || [],
  bookingTerms: state => state.excursion?.booking_terms || '',
  caloriesInt: state => state.excursion?.calories_int || 0,
  cancellationTerms: state => state.excursion?.cancellation_terms || '',
  currentUserRating: state => state.excursion?.currentUserRating || 0,
  description: state => state.excursion?.description || '',
  distanceInt: state => state.excursion?.distance_int || 0,
  durationInt: state => state.excursion?.duration_int || 0,
  entity: state => state.excursion?.entity || '',
  favorited: state => state.excursion?.favorited || false,
  guide: state => state.excursion?.guide || null,
  hasTickets: state => state.excursion?.has_tickets || false,
  id: state => state.excursion?.id || null,
  images: state => state.excursion?.images || [],
  pictures: state => state.excursion?.pictures || [],
  label: state => state.excursion?.label || '',
  languages: state => state.excursion?.languages || [],
  level: state => state.excursion?.level || null,
  places: state => state.excursion?.places || [],
  rating: state => state.excursion?.rating || 0,
  rutube: state => state.excursion?.rutube || '',
  vkontakte: state => state.excursion?.vk_iframe || '',
  season: state => state.excursion?.season || null,
  seasons: state => state.excursion?.seasons || [],
  tags: state => state.excursion?.tags || [],
  title: state => state.excursion?.title || '',
  type: state => state.excursion?.type || '',
  userTravels: state => state.excursion?.user_travels || null,
  youtube: state => state.excursion?.youtube || '',
  mediaSource: state => state.excursion?.media_source || null
}
