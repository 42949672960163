import { render, staticRenderFns } from "./index.vue?vue&type=template&id=433d401e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/InputComponent/index.vue').default,Button: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Button/index.vue').default,EntityContent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/EntityContent/index.vue').default,SearchPlaces: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SearchPlaces/index.vue').default,SearchExcursions: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SearchExcursions/index.vue').default,SearchRoutes: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SearchRoutes/index.vue').default,SearchEvents: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SearchEvents/index.vue').default,SearchNews: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SearchNews/index.vue').default,Tag: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Tag/index.vue').default,Tags: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Tags/index.vue').default,Section: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Section/index.vue').default,SectionBannerAppMobile: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionBillboard/index.vue').default})
