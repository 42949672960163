//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import seo from '@/middleware/seo'

export default {
  name: 'OrganizationsView',
  components: {
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    AgencyCard: () => import('@/components/common/AgencyCard/index'),
    Section: () => import('@/components/common/Section/index'),
    Button: () => import("@/components/common/Button"),
  },
  middleware: [seo],
  head() {
    return {
      title: this.SEOTitle,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.SEOTitle,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host + this.$route.fullPath,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      meta: 'pages/organizations/meta',
      agencies: 'pages/organizations/agencies',
      page: 'pages/organizations/page',
      pending: 'pages/organizations/pending',
      showPendingButton: 'pages/organizations/showPendingButton',
      city: 'cities/default',
    }),
    components() {
      return this.$config?.pages?.TravelAgenciesView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath
    }
  },
  mounted() {
    this.$store.commit('pages/organizations/setCityId', this.$config.singleCity ? this.city.id : null)
    this.$store.commit('pages/organizations/setPage', 1)
    this.$store.commit('pages/organizations/setLang', this.$i18n.locale)
    this.$store.dispatch('pages/organizations/agencies')
  },
  beforeDestroy() {
    this.$store.commit('pages/organizations/clear')
  },
  methods: {
    next() {
      this.$store.commit('pages/organizations/setPage', this.page + 1)
      this.$store.dispatch('pages/organizations/agencies')
    }
  }
}
