import { parser } from '@/utils/parser'

export const state = () => ({
  event: null,
  schedules: []
})

export const mutations = {
  setSchedules: (state, schedules) => state.schedules = schedules,
  setEvent: (state, event) => state.event = event,
  clearEvent: state => state.event = null,
  clearSchedules: state => state.schedules = []
}

export const actions = {
  async fetch ({ commit }, payload) {
    if (payload.clear) {
      commit('clearEvent')
    }
    const event = await this.$axios.$get(`/api/events/${payload.id}?${parser(payload)}&user=current`)
    commit('setEvent', event.data)
  },
  async schedules ({ commit }, payload) {
    if (payload.clear) {
      commit('clearSchedules')
    }
    const schedules = await this.$axios.$get(`/api/events/${payload.id}/schedules?${parser(payload)}&count=30&user=current`)
    commit('setSchedules', schedules.data)
  }
}

export const getters = {
  event: state => state.event,
  schedules: state => state.schedules
}
