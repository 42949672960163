import { render, staticRenderFns } from "./index.vue?vue&type=template&id=91cf9f12&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AgencyCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/AgencyCard/index.vue').default,Button: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Button/index.vue').default,Section: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Section/index.vue').default,SectionBannerAppMobile: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionBannerAppMobile/index.vue').default,SectionBillboard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SectionBillboard/index.vue').default})
