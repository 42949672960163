import { parser } from '@/utils/parser'

export const state = () => ({
  place: null,
  pending: false
})

export const mutations = {
  setPlace: (state, place) => state.place = place,
  setPending: (state, pending) => state.pending = pending
}

export const actions = {
  async fetch ({ commit }, payload) {
    commit('setPending', true)
    await this.$axios.$get(`/api/places/${payload.id}?${parser(payload)}`)
      .then(response => commit('setPlace', response.data))
      .catch(() => null)
      .finally(() => commit('setPending', false))
  }
}

export const getters = {
  place: state => state.place,
  pending: state => state.pending,

  accessibleEnvironment: state => state.place?.accessible_environment || '',
  additions: state => state.place?.additions || [],
  address: state => state.place?.address || '',
  audios: state => state.place?.audios || [],
  contacts: state => state.place?.contacts || null,
  currentUserRating: state => state.place?.currentUserRating || 0,
  description: state => state.place?.description || '',
  entity: state => state.place?.entity || '',
  favorited: state => state.place?.favorited || false,
  id: state => state.place?.id || null,
  images: state => state.place?.images || [],
  pictures: state => state.place?.pictures || [],
  label: state => state.place?.label || '',
  rating: state => state.place?.rating || 0,
  rutube: state => state.place?.rutube || '',
  socials: state => state.place?.socials || null,
  status: state => state.place?.entity || '',
  tags: state => state.place?.tags || [],
  title: state => state.place?.title || '',
  type: state => state.place?.type || '',
  userTravels: state => state.place?.user_travels || [],
  workHours: state => state.place?.work_hours || null,
  youtube: state => state.place?.youtube || '',
  vkontakte: state => state.place?.vk_iframe || '',
  mediaSource: state => state.place?.media_source || null,
  agencyId: state => state.place?.agency_id || null
}
