//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AccountTravelView',
  components: {
    Icon24FillTrash: () => import('@/assets/icons/Icon24FillTrash.svg?inline'),
    RoundButton: () => import('@/components/common/RoundButton'),
    RouteCard: () => import('@/components/common/RouteCard/index'),
    EventCard: () => import('@/components/common/EventCard/index'),
    PlaceCard: () => import('@/components/common/PlaceCard/index'),
    ExcursionCard: () => import('@/components/common/ExcursionCard/index'),
    EmptyEntity: () => import('@/components/common/EmptyEntity/index')
  },
  layout: 'personal-area',
  async fetch ({
    app,
    store,
    params
  }) {
    await store.dispatch('travel/fetch', {
      id: params.id,
      params: {
        lang: app.i18n.locale
      }
    })
  },
  head () {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: 'website',
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t('pages.index.seo.site-name'),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + '/meta-image-default-small.jpg?v=2',
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      travel: 'travel/travel',
      events: 'travel/events',
      places: 'travel/places',
      routes: 'travel/routes',
      excursions: 'travel/excursions'
    }),
    title () {
      return this?.travel?.title
    },
    started_at () {
      return this?.$moment.unix(this?.travel?.started_at)
        .format('DD.MM.YYYY, HH:mm')
    },
    stopped_at () {
      return this?.$moment.unix(this?.travel?.stopped_at)
        .format('DD.MM.YYYY, HH:mm')
    },
    link () {
      return this.$i18n.locale === 'ru'
        ? '/account/travels'
        : `/${this.$i18n.locale}/account/travels`
    },
    show () {
      return this.places.length > 0 ||
        this.routes.length > 0 ||
        this.excursions.length > 0 ||
        this.events.length > 0
    }
  },
  methods: {
    avatar (item) {
      return item?.guide?.images[0] || item?.agency?.images[0] || ''
    },
    addressEvent (event) {
      return event?.place?.address || event?.address || ''
    },
    remove () {
      this.$axios.$delete(`/api/travels/${this.$route.params.id}`)
        .then(() => {
          this.$router.push(this.link)
        })
    }
  }
}
