//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TopEntityAgencyProfile',
  components: {
    ImageComponent: () => import('@/components/common/ImageComponent'),
    SocialsSquaredComponent: () => import('@/components/common/SocialsSquaredComponent'),
    Icon24FillEdit: () => import('@/assets/icons/Icon24FillEdit.svg?inline'),
    Icon24FillEmail: () => import('@/assets/icons/Icon24FillEmail.svg?inline'),
    Icon24FillPhone: () => import('@/assets/icons/Icon24FillPhone.svg?inline'),
    Icon24FillWeb: () => import('@/assets/icons/Icon24FillWeb.svg?inline'),
    Icon24FillBusiness: () => import('@/assets/icons/Icon24FillBusiness.svg?inline'),
    LinkMail: () => import('@/components/common/LinkMail'),
    LinkPhone: () => import('@/components/common/LinkPhone'),
    LinkSite: () => import('@/components/common/LinkSite')
  },
  props: {
    agency: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    title () {
      return this?.agency?.title
    },
    type () {
      switch (this?.agency?.type) {
        case 'travel': {
          return this.$t('components.top-entity-agency-profile.type-travel-agency')
        }
        case 'org': {
          return this.$t('components.top-entity-agency-profile.type-organization')
        }
        case 'guide': {
          return this.$t('components.top-entity-agency-profile.type-guide')
        }
        default: {
          return ''
        }
      }
    },
    status () {
      return this?.agency?.status
    },
    image () {
      return this?.agency?.image
    },
    email () {
      return this?.agency?.email
    },
    phone () {
      return this?.agency?.phone
    },
    website () {
      return this?.agency?.website
    },
    address () {
      return this?.agency?.address
    },
    socials () {
      return this?.agency?.socials
    },
    showSocials () {
      return this.socials === null ? false : Object.values(this.socials).filter(item => item !== null).length > 0;
    }
  },
  methods: {
    openPopupEditAgency () {
      this.$store.commit('popup/open', {
        name: 'PopupEditAgency',
        params: {
          style: {
            width: '632px',
            maxHeight: '100vh'
          },
          gradient: true
        },
        options: this?.agency
      })
    }
  }
}
