//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapGetters} from "vuex";
import Section from "@/components/common/Section";
import SectionPartners from "@/components/common/SectionPartners";
import EmptyHeroSlider from "@/components/common/EmptyHeroSlider";

export default {
  name: 'TestView',
  components: {
    EmptyHeroSlider,
    SectionPartners,
    Section
  },
  validate() {
    return process.env.NODE_ENV !== 'production'
  },
  data() {
    return {
      email: '',
      allowedLanguages: '',
      allowedViewports: 'sm',
      items: 1,
      columns: 3,
      buttons: true,
      pagination: true,
      intersection2: false,
      intersection3: false,
      intersection4: false,
      response: {},
      days: [],
      more: 2,
      context: null,
      current: null,
      places: [],
      pending: true,
    }
  },
  computed: {
    ...mapGetters({
      promos: 'journals/promo',
      excursions: 'excursions/excursions',
      city: 'cities/default',
    }),
    checkViewportLanguage() {
      return this.$checkViewportLanguage({
        allowedLanguages: this.allowedLanguages,
        allowedViewports: this.allowedViewports,
      })
    },
    agenciesGridParams() {
      return {
        city_id: this.city.id,
        count: 8,
        page: 1,
      }
    },
  },
  watch: {
    items() {
      this.pending = true
    }
  },
  mounted() {
    this.$store.dispatch("journals/getPromo", {
      params: {
        resolution: "micro",
        lang: this.$i18n.locale
      }
    }).then(() => {
      this.pending = false
    })
    this.$axios.get(`/api/events/2058/schedules-calendar`)
      .then(response => {
        this.response = response.data.data
        for (const key in response.data.data) {
          this.days.push(this.$moment(key, 'DD-MM-YYYY').unix())
        }
      })
  },
  methods: {
    changePending(pending) {
      this.pending = pending
      setTimeout(() => {
        this.pending = false
      }, 1000)
    },
    intersected(index) {
      this[`intersection${index}`] = true
    },
    toggle(day) {
      this.current = day
      this.context = this.response[this.$moment.unix(day).format('DD-MM-YYYY')]
    },
    weekend(day) {
      return day === this.$moment.unix(day).day('Sunday').unix() ||
        day === this.$moment.unix(day).day('Saturday').unix()
    },
    openOrderPopup() {
      this.$store.commit('popup/open', {
        name: 'PopupOrder',
        params: {
          style: {
            maxHeight: '100vh'
          }
        },
        options: {
          day: '',
          info: [],
          entity: '',
          id: '',
          status: 'available'
        }
      })
    }
  }
}
