//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {featureNormalizer} from "@/utils/feature-normalizer";
import route from "~/middleware/pages/route";

export default {
  name: "RouteView",
  components: {
    SectionSharing: () => import("@/components/common/SectionSharing"),
    AudioPlayer: () => import("@/components/common/AudioPlayer"),
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    SectionComments: () => import("@/components/common/SectionComments"),
    SectionTags: () => import("@/components/common/SectionTags"),
    SectionBannerAppMobile: () => import("@/components/common/SectionBannerAppMobile"),
    Icon24FillFavorites: () => import("@/assets/icons/Icon24FillFavorites.svg?inline"),
    Icon24FillDistance: () => import("@/assets/icons/Icon24FillDistance.svg?inline"),
    EntityContent: () => import("@/components/common/EntityContent"),
    TopTitleComponent: () => import("@/components/common/TopTitleComponent"),
    SocialVideoFrameComponent: () => import("@/components/common/SocialVideoFrameComponent"),
    RoundButton: () => import("@/components/common/RoundButton"),
    TopEntityRating: () => import("@/components/common/TopEntityRating"),
    TopEntityComponent: () => import("@/components/common/TopEntityComponent"),
    HorizontalEntityCard: () => import("@/components/common/HorizontalEntityCard"),
    PlaceCard: () => import("@/components/common/PlaceCard"),
    RouteCard: () => import("@/components/common/RouteCard/index"),
    Scroller: () => import("@/components/common/Scroller/index"),
    SliderWithBullets: () => import("@/components/common/SliderWithBullets/index"),
    Brochure: () => import("@/components/common/Brochure/index"),
    OnMap: () => import("@/components/common/OnMap/index"),
    AgencyCard: () => import("@/components/common/AgencyCard/index"),
    GuideCard: () => import("@/components/common/GuideCard/index"),
    Gallery: () => import("@/components/common/Gallery"),
    SectionBillboard: () => import("@/components/common/SectionBillboard/index"),
    Section: () => import("@/components/common/Section/index"),
    Hint: () => import("@/components/common/Hint/index"),
  },
  middleware: [route],
  data() {
    return {
      featuresCollection: [],
      routes: [],
      innerFavorited: false,
      hideHint: false
    };
  },
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.images) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.images) || this.SEOImage,
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      SEOType: "seo/type",
      SEOTitle: "seo/title",
      SEOKeywords: "seo/keywords",
      SEODescription: "seo/description",
      SEOImage: "seo/image",
      SEOUrl: "seo/url",
      SEOLocale: "seo/locale",
      SEOSiteName: "seo/siteName",
      city: "cities/default",
      types: "categories/places",
      route: "route/route",
      travels: "travels/travels",
      innerTravels: "travels/innerTravels",
      activePlayer: "audio-player/active",
      logged: "user/logged",
      media: "viewport/media",
      userTravels: "route/userTravels",
      id: "route/id",
      entity: "route/entity",
      title: "route/title",
      type: "route/type",
      description: "route/description",
      images: "route/images",
      pictures: "route/pictures",
      status: "route/status",
      accessible_environment: "route/accessibleEnvironment",
      tags: "route/tags",
      rating: "route/rating",
      duration: "route/durationInt",
      languages: "route/languages",
      calories: "route/caloriesInt",
      distance: "route/distanceInt",
      label: "route/label",
      currentUserRating: "route/currentUserRating",
      ageRestriction: "route/ageRestriction",
      season: "route/season",
      has_tickets: "route/hasTickets",
      level: "route/level",
      favorited: "route/favorited",
      places: "route/places",
      additions: "route/additions",
      guide: "route/guide",
      agency: "route/agency",
      rutube: "route/rutube",
      vkontakte: "route/vkontakte",
      youtube: "route/youtube",
      audios: "route/audios",
      mediaSource: "route/mediaSource",
      toasterOpen: "toasters/opened"
    }),
    components() {
      return this.$config?.pages?.RouteView?.components;
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile;
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard;
    },
    showSectionTags() {
      return this.components?.SectionTags;
    },
    showSectionComments() {
      return this.components?.SectionComments;
    },
    showContextAdditions() {
      return this.components?.ContextAdditions && this.$i18n.locale === "ru";
    },
    roundButtonSize() {
      switch (this?.$media()) {
        case "sm": {
          return "36px";
        }
        default: {
          return "48px";
        }
      }
    },
    location() {
      return this?.city?.location || this.$config.coords;
    },
    topGridColumns() {
      switch (this?.$media()) {
        case "md": {
          return 3;
        }
        default: {
          return 1;
        }
      }
    },
    topGridGap() {
      switch (this?.$media()) {
        case "sm": {
          return 16;
        }
        default: {
          return 32;
        }
      }
    },
    travelId() {
      return this?.travels[0]?.id;
    },
    gallery() {
      return this.images.map((item, index) => ({
        image: item,
        id: index,
        title: "",
        description: ""
      }));
    },
    image() {
      return this?.route?.images[0];
    },
    ageRestrictionTitle() {
      return this?.ageRestriction.title;
    },
    levelTitle() {
      return this?.level?.title;
    },
    seasons() {
      return this?.route?.seasons
    },
    showMap() {
      return (this?.route?.direction?.length > 0 || this?.route?.places.length > 0) &&
        (this.$hd() || this.$lg() || this.$md());
    },
  },
  created() {
    this.$store.commit("audio-player/setPause");
    this.innerFavorited = this.favorited;
    this.$store.commit("travels/setInnerTravels", !!this.userTravels[0]?.id);
  },
  async mounted() {
    await this.getTrips();
    setTimeout(() => {
      let collection = this.route?.places?.map((item) => {
        return featureNormalizer(item);
      });
      if (this.route?.direction?.length > 0) {
        collection = [...collection, {
          type: "Feature",
          properties: [],
          geometry: {
            type: "LineString",
            coordinates: this.route?.direction
          }
        }];
      }
      this.featuresCollection = collection;
    }, 1);
  },
  methods: {
    toggleFavorite() {
      if (this.logged) {
        this.$axios.$post(`/api/${this?.entity}/${this?.id}/favorites`)
          .then(() => {
            this.innerFavorited = !this.innerFavorited;
          });
      } else {
        this.$login();
      }
    },
    async getTrips() {
      this.routes = await this.$axios.get(`/api/routes?category_id=${this?.route?.category_id}&lang=${this.$i18n.locale}`)
        .then(resolve => resolve.data.data.filter(item => item.id !== this.id));
    },
    toggleTravel() {
      if (this.logged) {
        if (this.innerTravels) {
          this.hideHint = true;
        }
        this.travels.length > 0
          ? this.addAndRemoveTravel()
          : this.openPopupAddTravel();
      } else {
        this.$login();
      }
    },
    addAndRemoveTravel() {
      if (this.innerTravels) {
        this.$axios.$post(`api/routes/${this.$route.params.id}/travels/${this.travelId}/remove`)
          .then(() => this.$store.commit("travels/setInnerTravels", false));
      } else {
        this.$axios.$post(`api/routes/${this.$route.params.id}/travels/${this.travelId}/add`)
          .then(() => this.$store.commit("travels/setInnerTravels", true))
          .catch((error) => {
            if (!this.toasterOpen) {
              // Первично задает данные для обертки тостера
              this.$store.commit("toasters/open", {
                params: {
                  position: {
                    vertical: "bottom",
                    horizontal: "right"
                  },
                  size: {
                    maxWidth: "400px",
                  }
                },
              });
            }
            this.$store.commit("toasters/setToasters", {
              id: this.$randomString(),
              status: "danger",
              componentInfo: {
                componentName: "ToasterText",
                props: {
                  text: error?.response?.data?.message
                }
              }
            });
          });
      }
    },
    openPopupAddTravel() {
      this.$store.commit("popup/open", {
        name: "PopupAddTravel",
        params: {
          style: {
            width: "100%",
            maxWidth: "632px",
            maxHeight: "100vh",
            overflow: "visible",
            position: "relative",
            overflowY: "visible",
            overflowX: "visible"
          },
          objectId: this.$route.params.id,
          entity: this.entity
        }
      });
    },
    openPopupOrder({schedule}) {
      if (this.available(schedule)) {
        if (this.logged) {
          const width = () => {
            switch (this.media) {
              case "sm": {
                return "100%";
              }
              case "md": {
                return "100%";
              }
              default: {
                return "632px";
              }
            }
          };
          this.$store.commit("popup/open", {
            name: "PopupOrder",
            params: {
              style: {
                width: width(),
                maxHeight: "100vh"
              }
            },
            options: {
              day: this.dayMonthYear(schedule),
              info: schedule[this.dayMonthYear(schedule)]
                .filter(item => item.status === "available" && item.items_remain !== 0),
              entity: this.entity,
              id: this.id,
              status: "available"
            }
          });
        } else {
          this.$login();
        }
      }
    },
    showHint(schedule) {
      return this.active(schedule) || this.available(schedule);
    },
    contentHint(schedule) {
      if (this.available(schedule)) {
        return schedule[this.dayMonthYear(schedule)]
          .filter(item => item.status === "available" && item.items_remain !== 0)
          .slice(0, 1); // выводим только 1 элемент в массиве
      } else if (this.active(schedule)) {
        return schedule[this.dayMonthYear(schedule)]
          .filter(item => item.status === "active");
      }
    }
  }
};
