//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionAgencyBranches",
  components: {
    EmptyEntity: () => import("@/components/common/EmptyEntity"),
    AgencyBranchCard: () => import("@/components/common/AgencyBranchCard"),
    VueCustomScrollbar: () => import("vue-custom-scrollbar"),
    OnMap: () => import("@/components/common/OnMap/index"),
    Section: () => import("@/components/common/Section/index"),
  },
  props: {
    id: {
      type: [Number, String],
      default: () => 0
    },
    showTitle: {
      type: Boolean,
      default: () => true,
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    },
    private: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      branches: "agency-branches/branches",
      features: "agency-branches/features",
    }),
    payload() {
      return this.private ? {
        id: this.id,
        clear: true,
        params: {
          lang: this.$i18n.locale,
          resolution: "micro",
          user: "current"
        }
      } : {
        id: this.id,
        clear: true,
        params: {
          lang: this.$i18n.locale,
          resolution: "micro",
        }
      };
    },
    show() {
      return this?.branches?.length > 0;
    }
  },
  mounted() {
    if (this.id) {
      this.$store.dispatch("agency-branches/fetch", this.payload);
    }
  }
};
