// Паттерн на проверку сайта. Если значение соответсвует - true, иначе - false.

import {Plugin} from '@nuxt/types'

type Site = string | '';

declare module 'vue/types/vue' {
  interface Vue {
    $isSite(site: Site): boolean
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $isSite(site: Site): boolean
  }

  interface Context {
    $isSite(site: Site): boolean
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $isSite(site: Site): boolean
  }
}

const pluginIsSite: Plugin = (context, inject) => {
  inject('isSite', (site: Site) => {
    // const regexpSites = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)([\da-z.-]+)\.([a-z.]{2,10})([/\w .-]*)*\/?$/
    const regexpSites = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([\da-zа-я.-]+)\.([a-zа-я.]{2,10})([/\w .-]*)*\/?(?::\d+)?(?:[^\s]*)?$/
    return regexpSites.test(site)
  })
}

export default pluginIsSite
