//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {parser} from "@/utils/parser";
import accountAgencyPlacesValidation from "@/middleware/account-agency-places-validation";
import accountAgencyProfileValidation from "@/middleware/account-agency-profile-validation";

export default {
  name: "AccountAgencyProfileView",
  components: {
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    SectionAgencyLegalInformation: () => import("@/components/common/SectionAgencyLegalInformation"),
    TradingPlatformMemberBecome: () => import("@/components/common/TradingPlatformMemberBecome"),
    Icon24FillPlus: () => import('@/assets/icons/Icon24FillPlus.svg?inline'),
    Section: () => import("@/components/common/Section"),
    RoundButton: () => import("@/components/common/RoundButton"),
    Empty: () => import("@/components/common/Empty"),
    PlaceCard: () => import("@/components/common/PlaceCard"),
    Tabs: () => import("@/components/common/Tabs/index"),
    Button: () => import("@/components/common/Button/index"),
    PendingButton: () => import("@/components/common/PendingButton/index"),
    TopEntityAgencyProfile: () => import("@/components/common/TopEntityAgencyProfile/index")
  },
  layout: "personal-area-agency",
  middleware: [accountAgencyProfileValidation, accountAgencyPlacesValidation],
  head() {
    return {
      title: this.$t("pages.account-agency-profile.seo.title"),
      meta: this.$createSEOMeta({
        og: {
          type: "website",
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
          url: this.SEOUrl || this.$store.getters.host,
          locale: this.SEOLocale || `${this.$i18n.locale}_${this.$i18n.locale.toUpperCase()}`,
          site_name: this.SEOSiteName || this.$t("pages.index.seo.site-name"),
        },
        vk: {
          image: this.SEOImage || this.$store.getters.host + "/meta-image-default-small.jpg?v=2",
        }
      })
    };
  },
  computed: {
    ...mapGetters({
      agency: "account-agency-profile/agency",
      places: "account-agency-places/places",
      meta: "account-agency-places/meta",
      pending: "account-agency-places/pending",
      city: 'cities/default',
      sm_registered: "account-agency-profile/sm_registered",
      addPlaceNotEmpty: 'add-place/notEmpty',
      toasterOpen: 'toasters/opened',
      toasters: 'toasters/toasters'
    }),
    agency_id() {
      return Number(this?.$route?.params?.agency_id);
    },
    status() {
      return this?.agency?.status;
    },
    description() {
      return this?.agency?.description;
    },
    additions() {
      return this?.agency?.additions;
    },
    showButton() {
      return this?.meta?.last_page !== this?.meta?.current_page;
    },
    url() {
      return this.$store.state.host + this?.$route?.fullPath;
    },
  },
  created() {
    this.$store.commit("layout-personal-area-agency/setButtonBackLink", "/account/agencies");
    this.$store.commit("layout-personal-area-agency/setButtonBackTitle", this.$t("layouts.personal-area-layout.link-my-organizations"));
  },
  mounted() {
    if (!this.toasterOpen && this.status === 'new') {
      // Первично задает данные для обертки тостера
      this.$store.commit("toasters/open", {
        params: {
          position: {
            vertical: "top",
            horizontal: "right"
          },
          size: {
            maxWidth: "400px",
          }
        },
      });
    }
    if (this.toasters?.length === 0) {
      // Добавление тоста в тостер
      this.$store.commit("toasters/setToasters", {
        id: this.agency_id,
        status: 'warning',
        time: 6,
        componentInfo: {
          componentName: "ToasterText",
          props: {
            text: this.$t("pages.agency-profile.toaster-text")
          }
        }
      });
    }
  },
  methods: {
    link(id) {
      return `/account/agencies/${this.$route.params.agency_id}/profile/places/${id}`;
    },
    openPopupAddPlace() {
      if(this.addPlaceNotEmpty) {
        this.$store.commit('popup/open', {
          name: 'PopupPreEntity',
          params: {
            entity: 'add-place',
            popup: 'PopupAddPlace',
            style: {
              width: this?.$media() === 'sm' ? '100%' : '632px',
              maxHeight: '100%'
            },
          }
        })
      } else {
        this.$store.commit("popup/open", {
          name: "PopupAddPlace",
          params: {
            style: {
              width: "632px",
              maxHeight: '100vh'
            },
            gradient: true
          }
        })
      }
    },
    next() {
      if (this.meta?.current_page < this.meta?.last_page) {
        this.$store.dispatch('account-agency-places/fetch',{
          params: {
            resolution: "micro",
            page: this.meta?.current_page + 1,
            count: 9,
            lang: this.$i18n.locale,
            city_id: this.$config.singleCity ? this.city.id : null,
          }
        })
      }
    }
  }
};
