//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'
import news from '~/middleware/pages/new'

export default {
  name: 'NewView',
  components: {
    SectionSharing: () => import("@/components/common/SectionSharing"),
    ContextAdditions: () => import("@/components/common/ContextAdditions"),
    Gallery: () => import("@/components/common/Gallery"),
    TopTitleComponent: () => import('@/components/common/TopTitleComponent'),
    EntityContent: () => import('@/components/common/EntityContent'),
    SocialVideoFrameComponent: () => import('@/components/common/SocialVideoFrameComponent'),
    TopEntityComponent: () => import('@/components/common/TopEntityComponent'),
    Brochure: () => import('@/components/common/Brochure/index'),
    SectionBannerAppMobile: () => import('@/components/common/SectionBannerAppMobile/index'),
    SectionBillboard: () => import('@/components/common/SectionBillboard/index'),
    Section: () => import('@/components/common/Section/index'),
  },
  middleware: [news],
  head() {
    return {
      title: this.title,
      meta: this.$createSEOMeta({
        og: {
          type: this.SEOType,
          title: this.title,
          keywords: this.SEOKeywords,
          description: this.SEODescription,
          image: this.$seoImage(this.images) || this.SEOImage,
          url: this.SEOUrl,
          locale: this.SEOLocale,
          site_name: this.SEOSiteName
        },
        vk: {
          image: this.$seoImage(this.images) || this.SEOImage,
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      SEOType: 'seo/type',
      SEOTitle: 'seo/title',
      SEOKeywords: 'seo/keywords',
      SEODescription: 'seo/description',
      SEOImage: 'seo/image',
      SEOUrl: 'seo/url',
      SEOLocale: 'seo/locale',
      SEOSiteName: 'seo/siteName',
      cities: 'cities/cities',
      news: 'piece-news/news',
      rutube: 'piece-news/rutube',
      vkontakte: 'piece-news/vkontakte',
      youtube: 'piece-news/youtube',
      title: 'piece-news/title',
      type: 'piece-news/type',
      annotation: 'piece-news/annotation',
      description: 'piece-news/description',
      additions: 'piece-news/additions',
      images: 'piece-news/images',
      pictures: 'piece-news/pictures',
      status: 'piece-news/status',
      updatedAt: 'piece-news/updatedAt',
      createdAt: 'piece-news/createdAt',
      mediaSource: 'piece-news/mediaSource'
    }),
    components() {
      return this.$config?.pages?.NewView?.components
    },
    showSectionBannerAppMobile() {
      return this.components?.SectionBannerAppMobile
    },
    showSectionBillboard() {
      return this.components?.SectionBillboard
    },
    showContextAdditions() {
      return this.components?.ContextAdditions
    },
    showGallery() {
      return this.components?.Gallery && this.images.length > 1
    },
    cityTitle() {
      const city = this?.cities?.find(item => item?.id === this?.news?.city_id)
      return city?.title || ''
    },
    time() {
      return this.$moment.unix(this?.updatedAt || this?.createdAt)
        .format('DD.MM.YYYY HH:mm')
    },
    gallery() {
      return this.images.map((item, index) => ({
        image: item,
        id: index,
        title: '',
        description: ''
      }))
    },
    image() {
      return this?.news?.images[0]
    },
    typeText() {
      return this.$t('pages.new-view.suptitle')
    }
  }
}
