import moment from 'moment'
import { parser } from '@/utils/parser'
import { errors } from '@/utils/errors'
import { append } from '@/utils/append'
import defaultStore from '@/utils/excursion-default-store-config'

export const state = () => ({
  accessible_environment: false,
  agency_id: null,
  annotation: null,
  city_id: null,
  config: { headers: { 'Content-Type': 'multipart/form-data' } },
  days: null,
  description: null,
  disabled: false,
  distance_int: null,
  duration_int: null,
  edit: false,
  entity: null,
  errors: null,
  hours: null,
  id: null,
  pictures: [],
  level_id: null,
  season_id: null,
  levels: [],
  seasons: [],
  seasonsForCheckbox: [],
  localeErrors: { hours: null, minutes: null },
  minutes: null,
  pending: false,
  places: [],
  category_id: null,
  title: null,
  tourism_type_id: null,
  tourism_types: [],
  additions: []
})

export const mutations = {
  deleteErrorsByKey: (state, key) => state.errors = errors(key, state.errors),
  setAdditions: (state, additions) => {
    state.additions = additions
    state.errors = errors('additions', state.errors)
  },
  setAgencyId: (state, agency_id) => state.agency_id = agency_id,
  setLocaleErrors: (state, localeErrors) => state.localeErrors = localeErrors,
  setDays: (state, days) => {
    state.errors = errors('days', state.errors)
    state.errors = errors('duration_int', state.errors)
    state.days = days
    state.duration_int = moment.duration({
      minutes: state.minutes,
      hours: state.hours,
      days: state.days
    }).asMinutes()
  },
  setHours: (state, hours) => {
    state.errors = errors('hours', state.errors)
    state.errors = errors('duration_int', state.errors)
    state.hours = hours
    state.duration_int = moment.duration({
      minutes: state.minutes,
      hours: state.hours,
      days: state.days
    }).asMinutes()
    if (state.hours > 23) {
      state.errors = { ...state.errors, ...{ hours: [state.localeErrors.hours] } }
    }
  },
  setMinutes: (state, minutes) => {
    state.errors = errors('minutes', state.errors)
    state.errors = errors('duration_int', state.errors)
    state.minutes = minutes
    state.duration_int = moment.duration({
      minutes: state.minutes,
      hours: state.hours,
      days: state.days
    }).asMinutes()
    if (state.minutes > 55 || state.minutes % 5 !== 0) {
      state.errors = { ...state.errors, ...{ minutes: [state.localeErrors.minutes] } }
    }
  },
  setEdit: (state, {
    edit,
    id
  }) => {
    state.edit = !!(edit || id)
  },
  setId: (state, id) => state.id = id,
  setTitle: (state, title) => {
    state.title = title
    state.errors = errors('title', state.errors)
  },
  setAnnotation: (state, annotation) => {
    state.annotation = annotation
    state.errors = errors('annotation', state.errors)
  },
  setDescription: (state, description) => {
    state.description = description
    state.errors = errors('description', state.errors)
  },
  setCityId: (state, city_id) => {
    state.city_id = city_id
    state.errors = errors('city_id', state.errors)
  },
  setCategoryId: (state, category_id) => {
    state.category_id = category_id
    state.errors = errors('category_id', state.errors)
    // todo выпилить это, еогда уберем subtype_id из выдачи
    state.errors = errors('subtype_id', state.errors)
  },
  setTourismTypeId: (state, tourism_type_id) => state.tourism_type_id = tourism_type_id,
  setEntity: (state, entity) => state.entity = entity,
  setDistanceInt: (state, distance_int) => {
    state.distance_int = distance_int
    state.errors = errors('distance_int', state.errors)
  },
  setDurationInt: (state, duration_int) => {
    state.duration_int = duration_int
    const duration = moment.duration(state.duration_int, 'minutes')
    state.days = duration.days()
    state.hours = duration.hours()
    state.minutes = duration.minutes()
  },
  setLevelId: (state, level_id) => state.level_id = level_id,
  setSeasonId: (state, season_id) => state.season_id = season_id,
  setSeasons: (state, seasons) => {
    state.seasons = seasons;
    if (state.seasonsForCheckbox.length === 0) {
      state.seasonsForCheckbox = seasons.map(item => ({
        id: item.id,
        title: item.title,
        checked: false
      }));
    }
  },
  setSeasonsForCheckbox: (state, seasonsForCheckbox) => state.seasonsForCheckbox = seasonsForCheckbox,
  setPictures: (state, pictures) => {
    state.pictures = pictures
    state.errors = errors('images', state.errors)
    state.errors = errors('pictures', state.errors)
  },
  setPlaces: (state, places) => {
    state.places = places
    state.errors = errors('places', state.errors)
  },
  setLevels: (state, levels) => state.levels = levels,
  // setSeasons: (state, seasons) => state.seasons = seasons,
  setTourismTypes: (state, tourism_types) => state.tourism_types = tourism_types,
  setAccessibleEnvironment: (state, accessible_environment) => state.accessible_environment = accessible_environment,
  setErrors: (state, errors) => state.errors = { ...state.errors, ...errors },
  setPending: (state, pending) => state.pending = pending,
  setDisabled: (state, disabled) => state.disabled = disabled,
  clear: (state) => {
    for (const key in defaultStore) {
      state[key] = defaultStore[key]
    }
  }
}

export const actions = {
  async tourism_types ({ commit }, payload) {
    const tourism_types = await this.$axios.$get(`/api/tourism_types?${parser(payload)}`)
    commit('setTourismTypes', tourism_types.data)
  },
  async levels ({ commit }, payload) {
    const levels = await this.$axios.$get(`/api/lists/levels?${parser(payload)}`)
    commit('setLevels', levels.data)
  },
  async seasons ({ commit }, payload) {
    const levels = await this.$axios.$get(`/api/lists/seasons?${parser(payload)}`)
    commit('setSeasons', levels.data)
  },
  async post ({
    state,
    commit
  }) {
    commit('setPending', true)
    const data = append(state)
    await this.$axios.$post('/api/excursions', data, state.config)
      .catch(errors => commit('setErrors', errors.response.data))
    commit('setPending', false)
  },
  async put ({
    state,
    commit
  }, payload) {
    commit('setPending', true)
    const data = append(state)
    await this.$axios.$post(`/api/excursions/${payload.id}`, data, state.config)
      .catch(errors => commit('setErrors', errors.response.data))
    commit('setPending', false)
  }
}

export const getters = {
  additions: state => state.additions,
  accessible_environment: state => state.accessible_environment,
  agency_id: state => state.agency_id,
  annotation: state => state.annotation,
  city_id: state => state.city_id,
  days: state => state.days,
  description: state => state.description,
  disabled: state => state.disabled,
  distance_int: state => state.distance_int,
  duration_int: state => state.duration_int,
  edit: state => state.edit,
  entity: state => state.entity,
  errors: state => state.errors,
  hours: state => state.hours,
  id: state => state.id,
  pictures: state => state.pictures,
  level_id: state => state.level_id,
  levels: state => state.levels,
  season_id: state => state.season_id,
  seasons: state => state.seasons,
  seasonsForCheckbox: state => state.seasonsForCheckbox,
  minutes: state => state.minutes,
  pending: state => state.pending,
  places: state => state.places,
  category_id: state => state.category_id,
  title: state => state.title,
  tourism_type_id: state => state.tourism_type_id,
  tourism_types: state => state.tourism_types,
  notEmpty: state => {
    return (state.title !== null && state.title?.length > 0) ||
      (state.annotation !== null && state.annotation?.length > 0) ||
      (state.description !== null && state.description?.length > 0) ||
      state.category_id !== null ||
      state.tourism_type_id !== null ||
      state.level_id !== null ||
      state.season_id !== null ||
      state.distance_int !== null ||
      state.duration_int !== null ||
      state.places.length > 0 ||
      state.pictures.length > 0 ||
      state.additions.length > 0 ||
      state.accessible_environment
  }
}
